import React, { useEffect, useState } from 'react';
import './jobMapPage.css';
import JobTable from './jobTable.jsx';  // Adjust the path to where the JobTable file is located
import BubbleMap from './bubbleMap.jsx';
import JobCard from './jobCard';
import useFavouriteJobs from './utils/manageFavouriteJobs.js';
import NoJobSelected from './noJobSelected';
import AnalysisPage from './analysisPage/analysisPage.jsx';
import MailchimpForm from './utils/MailchimpForm';
import { Helmet } from 'react-helmet';

function JobMapPage({ jobs, currency }) {
  const { favouriteJobsList, toggleFavouriteJobForUser } = useFavouriteJobs();

  const [jobsEnriched, setJobsEnriched] = useState([]);
  const [jobIDs, setJobIDs] = useState([]);
  const [tableSelection, setTableSelection] = useState(null);

  useEffect(() => {
    const updatedJobs = jobs.map(job => ({
      ...job,
      star: favouriteJobsList.includes(job.jobID), // Check if job is in favouriteJobsList
    }));
    setJobsEnriched(updatedJobs);
  }, [jobs, favouriteJobsList]);

  useEffect(() => {
    const ids = jobs.map(job => job.jobID);
    setJobIDs(ids);
  }, [jobs]); // Should depend on jobs, not jobsEnriched

  const selectedJob = tableSelection !== null ? jobsEnriched.find(job => job.jobID === tableSelection) : null;

  return (
    <div className="page">
      
      <Helmet>
        <title>KDB+ Jobs - All of the latest Kdb+ and q language jobs on the market</title>
        <meta name="description" content="Find a job in Kdb+ - The leading timeseries database technology" />
        <meta property="og:title" content="KDB+ Jobs Map" />
        <meta property="og:description" content="Find a job in Kdb+ - The leading timeseries database technology" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/preview_images/${"kdbJobs.png"}`} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="intro-container">
        <div className="intro">Uncover Global Opportunities in the World of KDB+</div>
      </div>
      <div className="main-container">
        <div className="selection-wrapper">
          <div className="map-container">
            <BubbleMap jobs={jobsEnriched} jobIDs={jobIDs} setJobIDs={setJobIDs}/>
          </div>
          <div className="right-selection">
            {selectedJob ? <JobCard job={selectedJob} maxSkills={7} showLinks={false} showSeeMoreButton={true} onStarClick={toggleFavouriteJobForUser} /> : <NoJobSelected jobs={jobs}/>}
          </div>
        </div>
        <div className="mysql-data-container">
          <JobTable jobs={jobsEnriched} jobIDs={jobIDs} setJobIDs={setJobIDs} currency={currency} setTableSelection={setTableSelection} onStarClick={toggleFavouriteJobForUser}/>
        </div>
      </div>
      <div className="intro-container">
        <MailchimpForm />
      </div>
    </div>
  );
}

export default JobMapPage;
