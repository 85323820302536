import React, { useEffect } from 'react';
import { setDirectListing, getDirectListing } from '../api'; // Ensure this path matches your project structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import './ControlJobListingsCheckbox.css'; // Adjust the path as necessary

function ControlJobListingsCheckbox({ company, directListing, setDirectListingState, setIsLoading }) {

  useEffect(() => {
    if (!company) { // Check if company is falsy (e.g., null, undefined, '')
      console.log('Company is not defined. Skipping fetch operation.');
      return; // Exit the useEffect callback if company is falsy
    }

    const fetchDirectListing = async () => {
      setIsLoading(true);
      try {
        const directListingStatus = await getDirectListing(company);
        setDirectListingState(directListingStatus.directListing); // Assuming the API returns an object with directListing
      } catch (error) {
        console.error('Failed to fetch direct listing: ', error);
      }
      setIsLoading(false);
    };

    fetchDirectListing();
  }, [company, setDirectListingState]); // Dependency array

  const handleCheckboxChange = async (event) => {
    setIsLoading(true);
    const newDirectListingStatus = event.target.checked;
    try {
      await setDirectListing(company, newDirectListingStatus);
      setDirectListingState(newDirectListingStatus); // Update state in parent component after successful API call
      console.log('Direct listing updated successfully');
    } catch (error) {
      console.error('Failed to update direct listing: ', error);
      // Optionally revert the checkbox state on failure
      setDirectListingState(!newDirectListingStatus);
    }
    setIsLoading(false);
  };

  const popupMsg = (
      <span>
          When you check this box, jobs sourced from major job boards will be removed from our site. This <b>grants you full control</b> to post jobs as desired, with the option to include direct links to your <b>company website</b>, <b>email addresses</b>, or <b>WhatsApp details</b> for applicant communications.
      </span>
  );

  return (
      <div className={`container ${!directListing ? 'redBorder' : ''}`}>
          <div className="labelContainer">
              <input
                  type="checkbox"
                  checked={directListing}
                  onChange={handleCheckboxChange}
                  className="checkbox"
              />
              <span className="labelText">
                  <b>Take control of your Job Listings</b>
                  <FontAwesomeIcon icon={faCircleQuestion} className="infoIcon"/>
                  <div className="custom-tooltip-chkbox">{popupMsg}</div>
              </span>
          </div>
          <p className="smallText">
              IMPORTANT: Ticking this box will <b>remove</b> any jobs that we are currently listing so that you can <b>publish your own</b> instead. This is a reversible process.
          </p>
      </div>
  );
}

export default ControlJobListingsCheckbox;
