import React, { useState, useEffect } from 'react';
import './chartsGrid.css';
import { convertCurrency } from '../currencyExchange.js';
import GenericBarChart from './barChart.jsx';
import GenericPieChart from './pieChart.jsx';
import GenericBarChartHorizontal from './barChartHorizontal.jsx';
import GenericLineChart from './lineChart.jsx';

const ChartsGrid = ({ jobData, currency, visibilityFlags }) => {

  const [countByCountry, setCountByCountry] = useState([]);
  const [countByPrimarySecondary, setCountByPrimarySecondary] = useState([]);
  const [countByEmploymentType, setCountByEmploymentType] = useState([]);
  const [countByWorkArrangement, setCountByWorkArrangement] = useState([]);
  const [countByJobTitle, setCountByJobTitle] = useState([]);
  const [avgPayByCountry, setAvgPayByCountry] = useState([]);
  const [skillCount, setSkillCount] = useState([]);
  const [avgPayBySkillPairing, setAvgPayBySkillPairing] = useState([]);
  const [payByExperience, setPayByExperience] = useState([]);
  const [payBySeniority, setPayBySeniority] = useState([]);
  const [jobsOverTime, setJobsOverTime] = useState([]);
  const [countByCompany, setCountByCompany] = useState([]);
  const [avgSalaryBoostBySkill, setAvgSalaryBoostBySkill] = useState([]);
  const [countByIndustry, setCountByIndustry] = useState([]);
  const [countByInstitutionType, setCountByInstitutionType] = useState([]);
  const [countBySource, setCountBySource] = useState([]);
  const [isMobile, setIsMobile] = useState(false);


/////// aggregations for data for each chart
useEffect(() => {
  if (jobData) {
    if (visibilityFlags.countByCountry) calculateCountByCountry(jobData);
    if (visibilityFlags.countByPrimarySecondary) calculateCountByPrimarySecondary(jobData);
    if (visibilityFlags.countByEmploymentType) calculateCountByEmploymentType(jobData);
    if (visibilityFlags.countByWorkArrangement) calculateCountByWorkArrangement(jobData);
    if (visibilityFlags.countByJobTitle) calculateCountByJobTitle(jobData);
    if (visibilityFlags.avgPayByCountry) calculateAvgPayByCountry(jobData);
    if (visibilityFlags.skillCount) calculateSkillCount(jobData);
    if (visibilityFlags.payBySkillPairing) calculateAvgPayBySkill(jobData);
    if (visibilityFlags.payByExperience) calculateAvgPayByExperience(jobData);
    if (visibilityFlags.payBySeniority) calculateAvgPayBySeniority(jobData);
    if (visibilityFlags.jobsOverTime) calculateJobCountOverTime(jobData);
    if (visibilityFlags.countByCompany) calculateCountByCompany(jobData);
    if (visibilityFlags.avgSalaryBoostBySkill) calculateAvgMaxSalaryBoost(jobData);
    if (visibilityFlags.countByIndustry) calculateCountByIndustry(jobData);
    if (visibilityFlags.countByInstitutionType) calculateCountByInstitution(jobData);
    if (visibilityFlags.countBySource) calculateCountBySource(jobData);
  }
}, [jobData, visibilityFlags]); // Add visibilityFlags to the dependency array

// set isMobile
useEffect(() => {
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Check on mount (in case the user is already on a mobile device)
  checkMobile();

  // Add event listener for window resize
  window.addEventListener('resize', checkMobile);

  // Cleanup function to remove the event listener
  return () => window.removeEventListener('resize', checkMobile);
}, []);


///////////////////////////////////////////////////
///////////////Aggregation functions///////////////

//////////////// job count by country ////////////
  const calculateCountByCountry = (data) => {

    let restOfWorldCount = 0;
    const countByCountry = jobData.reduce((acc, { country }) => {
      if (country) {
        acc[country] = (acc[country] || 0) + 1;
      }
      return acc;
    }, {});

    const totalJobs = Object.values(countByCountry).reduce((total, count) => total + count, 0);

    const calculatedPercentByCountry = Object.entries(countByCountry)
      .map(([country, count]) => {
        const percentage = (count / totalJobs) * 100;
        if (percentage < 2) {
          restOfWorldCount += count;
          return null; // We'll filter this out and add to 'Rest of the World'
        }
        return { country, percentage };
      })
      .filter(Boolean); // Filters out the null values for countries below 5%

    if (restOfWorldCount > 0) {
      calculatedPercentByCountry.push({ 
        country: 'Rest of the World', 
        percentage: (restOfWorldCount / totalJobs) * 100 
      });
    }

    setCountByCountry(calculatedPercentByCountry);
  };


//////////////// job count by primarySkill vs secondarySkill ////////////

  const calculateCountByPrimarySecondary = (jobData) => {
    let primaryKdbCount = 0;
    let secondaryKdbCount = 0;

    jobData.forEach(job => {
      if (job.primarySkills && job.primarySkills.toLowerCase().includes('kdb')) {
        primaryKdbCount++;
      }
      if (job.secondarySkills && job.secondarySkills.toLowerCase().includes('kdb')) {
        secondaryKdbCount++;
      }
    });

    const totalKdbCount = primaryKdbCount + secondaryKdbCount;
    const kdbProportions = totalKdbCount === 0 ? 
      [{ skillType: 'primary', percentage: 0 }, { skillType: 'secondary', percentage: 0 }] :
      [
        { skillType: 'primary', percentage: (primaryKdbCount / totalKdbCount) * 100 },
        { skillType: 'secondary', percentage: (secondaryKdbCount / totalKdbCount) * 100 }
      ];

    setCountByPrimarySecondary(kdbProportions);
  };


//////////////// count by employmentType ////////////

  const calculateCountByEmploymentType = (data) => {
    let totalJobs = 0;
    const countByEmploymentType = data.reduce((acc, { employmentType }) => {
      if (employmentType) {
        acc[employmentType] = (acc[employmentType] || 0) + 1;
        totalJobs++;
      }
      return acc;
    }, {});

    const calculatedPercentByEmploymentType = Object.entries(countByEmploymentType)
      .filter(([type, count]) => {
        const percentage = (count / totalJobs) * 100;
        if (percentage < 1) {
          return false;
        }
        return true;
      })
      .map(([type, count]) => ({ type, percentage: (count / totalJobs) * 100 }));

    //console.log('employment:', JSON.stringify(calculatedPercentByEmploymentType, null, 2));
    setCountByEmploymentType(calculatedPercentByEmploymentType);
  };

//////////////// count by jobTitle ////////////

  const calculateCountByJobTitle = (data) => {
    const jobTitleSynonyms = {
      'KDB+ Dev': ['KDB Developer', 'KDB+ Developer', 'KDB+/Q Developer', 'KDB/Q Developer', 'KDB Engineer', 'KDB+ Engineer', 'KDB+/Q Engineer', 'KDB/Q Engineer'],
      'Quant Dev': ['Quantitative Developer', 'Quant IT Developer'],
      'Quant Analyst': ['Quantitative Researcher', 'Quantitative Analyst', 'Quant Researcher', 'Quant Analyst', 'Quant Strategist'],
      'Trader': ['Trader'],
      'Software Dev': ['Software Developer', 'Software Engineer'],
      'Platform Engineer': ['Platform Engineer'],
      'Data Engineer': ['Data Engineer'],
      'Manager': ['Manager']
    };

    let totalJobs = 0;
    const countByJobTitle = data.reduce((acc, { jobTitle }) => {
      let matched = false;
      if (jobTitle) {
        for (const [mainTitle, synonyms] of Object.entries(jobTitleSynonyms)) {
          if (synonyms.some(synonym => new RegExp(synonym, 'i').test(jobTitle))) {
            acc[mainTitle] = (acc[mainTitle] || 0) + 1;
            matched = true;
          }
        }
      }

      totalJobs++;
      return acc;
    }, {});

    const calculatedPercentByJobTitle = Object.entries(countByJobTitle)
      .map(([title, count]) => ({ title, percentage: (count / totalJobs) * 100 }));

    setCountByJobTitle(calculatedPercentByJobTitle);
  };


  //////////////// count by workArrangement ////////////

  const calculateCountByWorkArrangement = (data) => {
    let totalJobs = 0;
    const countByWorkArrangement = data.reduce((acc, { workArrangement }) => {
      if (workArrangement) {
        acc[workArrangement] = (acc[workArrangement] || 0) + 1;
        totalJobs++;
      }
      return acc;
    }, {});

    const calculatedPercentByWorkArrangement = Object.entries(countByWorkArrangement)
      .filter(([type, count]) => {
        const percentage = (count / totalJobs) * 100;
        if (percentage < 1) {
          return false;
        }
        return true;
      })
      .map(([type, count]) => ({ type, percentage: (count / totalJobs) * 100 }));

    //console.log('employment:', JSON.stringify(calculatedPercentByWorkArrangement, null, 2));
    setCountByWorkArrangement(calculatedPercentByWorkArrangement);
  };

//////////////// maxSalary by country ////////////
  const calculateAvgPayByCountry = (data) => {
    const calculatedAvgPayByCountry = Object.entries(
      data.reduce((acc, { country, minSalary, maxSalary }) => {
        let salary = maxSalary;
        if (minSalary !== null && maxSalary !== null) {
          salary = (minSalary + maxSalary) / 2;
        } else if (minSalary === null && maxSalary !== null) {
          salary = maxSalary * 0.8;
        }

        if (salary) { // Ignore nulls and zeros
          if (!acc[country]) acc[country] = [];
          acc[country].push(salary);
        }
        return acc;
      }, {})
    )
    .filter(([_, salaries]) => salaries.length > 1) // filter countries with more than 5 jobs
    .map(([country, salaries]) => ({
      country,
      avgSalary: salaries.reduce((a, b) => a + b, 0) / salaries.length // calculate average here
    }));
    setAvgPayByCountry(calculatedAvgPayByCountry);
  };

//////////////// skill count ////////////
  const calculateSkillCount = (data) => {
      const totalJobAds = jobData.length;

      // Define a mapping of skills to combine
      const skillMapping = {
        'Unix shell scripting': 'Unix',
        'Node': 'Node.js',
      };

      // Define a set of skills to exclude
      const skillsToExclude = new Set([
        'Kdb+/q',
        'KDB'
      ]);

      const skillCount = jobData.reduce((acc, job) => {
        // Process primary skills
        const primarySkills = JSON.parse(job.primarySkills || '[]'); // Safely parse the JSON
        primarySkills.forEach(originalSkill => {
          // Check if the skill should be excluded
          if (originalSkill && !skillsToExclude.has(originalSkill)) {
            // Map skill to a new name if necessary
            const skill = skillMapping[originalSkill] || originalSkill;
            acc[skill] = (acc[skill] || 0) + 1;
          }
        });

        // Process secondary skills, counting each as half
        const secondarySkills = JSON.parse(job.secondarySkills || '[]');
        secondarySkills.forEach(originalSkill => {
          if (originalSkill && !skillsToExclude.has(originalSkill)) {
            const skill = skillMapping[originalSkill] || originalSkill;
            acc[skill] = (acc[skill] || 0) + 0.5;
          }
        });

        return acc;
      }, {});

      // Calculate the percentage of job ads for each skill
      const skillPercentage = Object.entries(skillCount).reduce((acc, [skill, count]) => {
        acc[skill] = (count / totalJobAds) * 100;
        return acc;
      }, {});

      // Filter skills with less than 10 appearances and convert to array with percentage
      const skillPercentageArray = Object.entries(skillPercentage)
        .filter(([skill, percentage]) => percentage > 3)
        .map(([skill, percentage]) => ({
          skill,
          percentage
        }));

      setSkillCount(skillPercentageArray); // Assuming setSkillCount can handle the new structure
  };

//////////////// avgPay by skill pairing ////////////

  const calculateAvgPayBySkill = (data) => {
    const skillPay = data.reduce((acc, { primarySkills, secondarySkills, minSalary, maxSalary }) => {
      let avgSalary = null;
      if (minSalary !== null && maxSalary !== null) {
        avgSalary = (minSalary + maxSalary) / 2;
      } else if (minSalary !== null) {
        avgSalary = minSalary;
      } else if (maxSalary !== null) {
        avgSalary = maxSalary;
      }

      if (avgSalary !== null) {
        const parsedPrimarySkills = JSON.parse(primarySkills || '[]');
        const parsedSecondarySkills = JSON.parse(secondarySkills || '[]');

        parsedPrimarySkills.concat(parsedSecondarySkills).forEach(skill => {
          if (!acc[skill]) acc[skill] = { totalPay: 0, count: 0 };
          acc[skill].totalPay += avgSalary;
          acc[skill].count++;
        });
      }

      return acc;
    }, {});

    // Filter out skills that match the patterns "*kdb*" or "*data*" case-insensitively
    const regex = /(kdb)/i;
    const filteredSkillPay = Object.entries(skillPay)
      .filter(([skill, { count }]) => count >= 6 && !regex.test(skill))
      .reduce((filteredAcc, [skill, { totalPay, count }]) => {
        filteredAcc.push({ skill, avgPay: totalPay / count });
        return filteredAcc;
      }, []);

    setAvgPayBySkillPairing(filteredSkillPay);
  };

//////////////// avg salary boost per skill ////////////

  const calculateAvgMaxSalaryBoost = (data) => {
    const skillSalary = {};
    let totalMaxSalaryNoSkill = 0;
    let countNoSkill = 0;
    const minOccurrences = 3

    data.forEach(({ primarySkills, secondarySkills, maxSalary }) => {

      const primarySkillsParsed = JSON.parse(primarySkills || '[]');
      const hasKdbInPrimary = primarySkillsParsed.some(skill => skill.toLowerCase().includes('kdb'));

      if (hasKdbInPrimary && maxSalary !== null) {
        const allSkills = primarySkillsParsed.concat(JSON.parse(secondarySkills || '[]'));

        if (allSkills.length === 0) {
          totalMaxSalaryNoSkill += maxSalary;
          countNoSkill++;
        }

        allSkills.forEach(skill => {
          if (!skillSalary[skill]) {
            skillSalary[skill] = { withSkill: 0, withoutSkill: 0, countWith: 0, countWithout: 0, occurrences: 0 };
          }
          skillSalary[skill].occurrences++;
        });
      }
    });

    // Calculate salaries for jobs with and without each skill
    data.forEach(item => {
      const itemSkills = JSON.parse(item.primarySkills || '[]').concat(JSON.parse(item.secondarySkills || '[]'));
      if (item.maxSalary !== null) {
        Object.keys(skillSalary).forEach(skill => {
          if (itemSkills.includes(skill)) {
            skillSalary[skill].withSkill += item.maxSalary;
            skillSalary[skill].countWith++;
          } else {
            skillSalary[skill].withoutSkill += item.maxSalary;
            skillSalary[skill].countWithout++;
          }
        });
      }
    });

    // Filter skills with fewer occurrences and calculate percentage differences
    const skillDifference = Object.entries(skillSalary)
      .filter(([_, { occurrences }]) => occurrences >= minOccurrences)
      .map(([skill, { withSkill, withoutSkill, countWith, countWithout }]) => {
        const avgWithSkill = countWith > 0 ? withSkill / countWith : 0;
        const avgWithoutSkill = countWithout > 0 ? withoutSkill / countWithout : 0;
        const percentageDiff = avgWithoutSkill !== 0 ? ((avgWithSkill - avgWithoutSkill) / avgWithoutSkill) * 100 : 0;

        return { skill, percentageDiff };
      });

    // Sort by percentage difference
    skillDifference.sort((a, b) => b.percentageDiff - a.percentageDiff);

    //console.log('salary boost:', JSON.stringify(skillDifference, null, 2));
    setAvgSalaryBoostBySkill(skillDifference);
  };

  const calculateAvgPayByExperience = (data) => {
    const experienceData = data.reduce((acc, { experience, minSalary, maxSalary }) => {
      let salary = null;

      // Calculate average salary if both minSalary and maxSalary are not null
      if (minSalary !== null && maxSalary !== null) {
        salary = (minSalary + maxSalary) / 2;
      } 
      // Use 80% of maxSalary if minSalary is null and maxSalary is not
      else if (minSalary === null && maxSalary !== null) {
        salary = maxSalary * 0.8;
      }

      // Add the calculated salary to the accumulator if it's not null
      if (salary !== null) {
        if (!acc[experience]) acc[experience] = [];
        acc[experience].push(salary);
      }

      return acc;
    }, {});

    // Get unique experience levels from the data
    const uniqueExperiences = Array.from(new Set(data.map(item => item.experience)));

    // Ensure continuity by filling gaps
    const payByExperience = uniqueExperiences.sort((a, b) => a - b)
      .map(exp => {
        return {
          experience: exp,
          averagePay: experienceData[exp] && experienceData[exp].length > 0
            ? experienceData[exp].reduce((a, b) => a + b, 0) / experienceData[exp].length
            : null
        };
      });

    //console.log('payByExperience:', JSON.stringify(payByExperience, null, 2));
    setPayByExperience(payByExperience);
  };


  const calculateAvgPayBySeniority = (data) => {
    // Accumulate salaries by seniority
    const salaryData = data.reduce((acc, { seniority, minSalary, maxSalary }) => {
      let salary = null;

      // Calculate average salary if both minSalary and maxSalary are not null
      if (minSalary !== null && maxSalary !== null) {
        salary = (minSalary + maxSalary) / 2;
      } 
      // Use 80% of maxSalary if minSalary is null and maxSalary is not
      else if (minSalary === null && maxSalary !== null) {
        salary = maxSalary * 0.8;
      }

      // Add the calculated salary to the accumulator if it's not null
      if (salary !== null) {
        if (!acc[seniority]) acc[seniority] = [];
        acc[seniority].push(salary);
      }

      return acc;
    }, {});

    // Create an array for each seniority level with the average salary
    const payBySeniority = ['junior', 'intermediate', 'senior'].map(level => {
      return {
        seniority: level,
        averagePay: salaryData[level] && salaryData[level].length > 0
          ? salaryData[level].reduce((sum, current) => sum + current, 0) / salaryData[level].length
          : null
      };
    });

    // Assuming you want to set this data to some state as in the original function
    //console.log('payByExperience:', JSON.stringify(payBySeniority, null, 2));
    setPayBySeniority(payBySeniority);
  };


  ////////////////JobsOverTime///////////////////
  const calculateJobCountOverTime = (data) => {
    const jobCounts = {};
    const hardcodedStartDate = new Date('2023-11-01'); // Replace with your desired start date

    // Function to get the start of the week for a given date
    const getStartOfWeek = (date) => {
      const start = new Date(date);
      start.setDate(start.getDate() - start.getDay()); // Adjust to Sunday (0), change to getDay() + 1 for Monday
      return new Date(start.getFullYear(), start.getMonth(), start.getDate());
    };

    // Collect all unique site names
    const allSites = new Set();
    data.forEach(({ site }) => {
      site.split(', ').forEach(siteName => allSites.add(siteName));
    });

    // Filter out data before the hardcoded start date
    const filteredData = data.filter(({ added }) => new Date(added) >= hardcodedStartDate);

    // Find the maximum date from the filtered data
    const maxDate = new Date(Math.max(...filteredData.map(({ added }) => new Date(added))));

    // Generate all week start dates between the hardcoded start date and max date
    for (let d = getStartOfWeek(hardcodedStartDate); d <= maxDate; d.setDate(d.getDate() + 7)) {
      const formattedDate = d.toISOString().split('T')[0];
      jobCounts[formattedDate] = { allJobs: 0 };

      // Initialize zero count for each site
      allSites.forEach(siteName => {
        jobCounts[formattedDate][siteName] = 0;
      });
    }

    // Parse and count jobs by site and week
    filteredData.forEach(({ added, site }) => {
      const weekStart = getStartOfWeek(new Date(added)).toISOString().split('T')[0];

      // Initialize jobCounts for the week if not already initialized
      if (!jobCounts[weekStart]) {
        jobCounts[weekStart] = { allJobs: 0 };
        allSites.forEach(siteName => {
          jobCounts[weekStart][siteName] = 0;
        });
      }

      // Split the site names and count for each
      const sitesArray = site.split(', ');

      // Count for all jobs - now considering each site the job is added on
      jobCounts[weekStart].allJobs += sitesArray.length;

      // Count for each individual site
      sitesArray.forEach(siteName => {
          jobCounts[weekStart][siteName]++;
      });
    });

    // Sort dates (weeks) and prepare data for chart
    const sortedWeeks = Object.keys(jobCounts).sort((a, b) => new Date(a) - new Date(b));
    const chartData = sortedWeeks.map(weekStart => ({
      weekStart,
      ...jobCounts[weekStart]
    }));

    setJobsOverTime(chartData);
  };

  //////////////// job count by company ////////////
  const calculateCountByCompany = (data) => {

    const countByCompany = data.reduce((acc, { company }) => {
      if (company) {
        acc[company] = (acc[company] || 0) + 1;
      }
      return acc;
    }, {});

    const totalJobs = Object.values(countByCompany).reduce((total, count) => total + count, 0);

    const calculatedPercentByCompany = Object.entries(countByCompany)
      .map(([company, count]) => {
        const percentage = (count / totalJobs) * 100;
        return { company, percentage };
      })
      .filter(Boolean); // Filters out the null values

    //console.log('chartData:', JSON.stringify(calculatedPercentByCompany, null, 2));
    setCountByCompany(calculatedPercentByCompany);
  };


//////////////// job count by industry ////////////
  const calculateCountByIndustry = (data) => {
    // Create a mapping of the most common industry for each company
    const industryByCompany = {};
    data.forEach(({ company, industry }) => {
      if (industry) {
        industryByCompany[company] = industryByCompany[company] || {};
        industryByCompany[company][industry] = (industryByCompany[company][industry] || 0) + 1;
      }
    });

    // Determine the most common industry for each company
    for (let company in industryByCompany) {
      const industries = industryByCompany[company];
      industryByCompany[company] = Object.keys(industries).reduce((a, b) => industries[a] > industries[b] ? a : b);
    }

    // Calculate count by industry
    let otherCount = 0;
    const countByIndustry = data.reduce((acc, { company, industry }) => {
      // Use the most common industry for the company if industry is null
      const industryKey = industry || industryByCompany[company] || 'Unknown';
      acc[industryKey] = (acc[industryKey] || 0) + 1;
      return acc;
    }, {});

    // Calculate total jobs
    const totalJobs = Object.values(countByIndustry).reduce((total, count) => total + count, 0);

    // Calculate percentage for each industry
    const calculatedPercentByIndustry = Object.entries(countByIndustry)
      .map(([industry, count]) => {
        const percentage = (count / totalJobs) * 100;
        if (percentage < 2) {
          otherCount += count;
          return null;
        }
        return { industry, percentage };
      })
      .filter(Boolean); // Filters out the null values for industries below 2%

    // Include 'Other' category if necessary
    if (otherCount > 0) {
      calculatedPercentByIndustry.push({ 
        industry: 'Other', 
        percentage: (otherCount / totalJobs) * 100 
      });
    }

    // Set the calculated percentages (adjust this line according to your application's state management)
    setCountByIndustry(calculatedPercentByIndustry);
  };



  //////////////// job count by industry ////////////
  const calculateCountByInstitution = (data) => {
    // Create a map of most common institutionType for each company
    const institutionTypeByCompany = data.reduce((acc, { company, institutionType }) => {
      if (!company || institutionType == null) return acc;

      if (!acc[company]) {
        acc[company] = { [institutionType]: 1 };
      } else {
        acc[company][institutionType] = (acc[company][institutionType] || 0) + 1;
      }
      return acc;
    }, {});

    // Determine the most common institutionType for each company
    Object.keys(institutionTypeByCompany).forEach(company => {
      const sortedTypes = Object.entries(institutionTypeByCompany[company])
                                .sort((a, b) => b[1] - a[1]);
      institutionTypeByCompany[company] = sortedTypes[0][0];
    });

    // Assign most common institutionType to entries with null institutionType
    const updatedData = data.map(item => {
      if (item.institutionType == null && institutionTypeByCompany[item.company]) {
        return { ...item, institutionType: institutionTypeByCompany[item.company] };
      }
      return item;
    });

    // Filter out entries with null or undefined institutionType
    const filteredData = updatedData.filter(({ institutionType }) => institutionType != null);

    let otherCount = 0;
    const countByInstitutionType = filteredData.reduce((acc, { institutionType }) => {
      const institutionTypeKey = institutionType;
      acc[institutionTypeKey] = (acc[institutionTypeKey] || 0) + 1;
      return acc;
    }, {});

    const totalJobs = Object.values(countByInstitutionType).reduce((total, count) => total + count, 0);

    const calculatedPercentByInstitutionType = Object.entries(countByInstitutionType)
      .map(([institutionType, count]) => {
        const percentage = (count / totalJobs) * 100;
        // Adjust the threshold as needed
        if (percentage < 0.5) {  // Example threshold
          otherCount += count;
          return null;
        }
        return { institutionType, percentage };
      })
      .filter(Boolean); // Filters out the null values for institution types below the threshold

    if (otherCount > 0) {
      calculatedPercentByInstitutionType.push({ 
        institutionType: 'Other', 
        percentage: (otherCount / totalJobs) * 100 
      });
    }

    //console.log('Calculated Percent by Institution Type:', JSON.stringify(calculatedPercentByInstitutionType, null, 2));
    setCountByInstitutionType(calculatedPercentByInstitutionType);
  };


//////////////// Employer vs Recruiter ////////////

  const calculateCountBySource = (data) => {
    let totalJobs = 0;
    const countBySource = data.reduce((acc, { source }) => {
      if (source) {
        acc[source] = (acc[source] || 0) + 1;
        totalJobs++;
      }
      return acc;
    }, {});

    const calculatedPercentBySource = Object.entries(countBySource)
      .filter(([type, count]) => {
        const percentage = (count / totalJobs) * 100;
        if (percentage < 1) {
          return false;
        }
        return true;
      })
      .map(([type, count]) => ({ type, percentage: (count / totalJobs) * 100 }));

    //console.log('employment:', JSON.stringify(calculatedPercentBySource, null, 2));
    setCountBySource(calculatedPercentBySource);
  };




///////////// html /////////////
  return (
    <div className="container-grid">

      {/* top employers by count */}
      {visibilityFlags.countByCompany && (
        <div className="grid-item">
          <GenericBarChartHorizontal
            data={countByCompany}
            dataKey="company"
            dataValue="percentage"
            title="Top Employers"
            yAxisAngle={-15}
            nameFormatter={(company) => "company"}
            valueFormatter={(percentage) => {
              const value = Number(percentage); // Convert to number if it's not already
              const rounded = Number(value.toPrecision(2)); // Round to 3 significant figures
              return `${rounded.toLocaleString()}%`; // Convert to string with percentage sign
            }}
            yAxisFontSize = {'10px'}
            infoDescription ="Number of job posts by employer as a percentage of all jobs within the filters you set - top 12 results"
          />
        </div>
      )}

      {/* job count by country pie chart */}
      {visibilityFlags.countByCountry && (
        <div className="grid-item">
         <GenericPieChart
          data={countByCountry}
          dataKey="country"
          dataValue="percentage"
          title="Jobs Count by Country"
          nameFormatter={(country) => country || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
          infoDescription ="Count of job posts by country"
        />
        </div>
      )}

      {/* job count by employmentType pie chart */}
      {visibilityFlags.countByEmploymentType && (
        <div className="grid-item">
         <GenericPieChart
          data={countByEmploymentType}
          dataKey="type"
          dataValue="percentage"
          title="Jobs Count by Employment Type"
          nameFormatter={(type) => type || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
        />
        </div>
      )}

      {/* job count by workArrangement pie chart */}
      {visibilityFlags.countByWorkArrangement && (
        <div className="grid-item">
         <GenericPieChart
          data={countByWorkArrangement}
          dataKey="type"
          dataValue="percentage"
          title="Jobs Count by Work Arrangement"
          nameFormatter={(type) => type || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
        />
        </div>
      )}

      {visibilityFlags.countByJobTitle && (
        <div className="grid-item">
          <GenericBarChartHorizontal
            data={countByJobTitle}
            dataKey="title"
            dataValue="percentage"
            title="KDB+ Job Titles"
            nameFormatter={(type) => type || 'Unknown'}
            valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
            yAxisFontSize = {'10px'}
            infoDescription ="Count of keywords included in job titles as a percentage of filtered jobs (includes synonyms)"
          />
        </div>
      )}

      {/* pay by country bar chart */}
      {visibilityFlags.avgPayByCountry && (
          <div className="grid-item">
              <GenericBarChart
                  data={avgPayByCountry}
                  dataKey="country"
                  dataValue="avgSalary"
                  title="Pay by Country"
                  nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
                  valueFormatter={(value) => `${value.toLocaleString()} ${currency}`}
                  xAxisAngle={-30}
                  xAxisFontSize={'10px'}
                  yAxisFontSize={'12px'}
                  infoDescription="Average advertised pay by country for filtered data"
                  maxBars={isMobile ? 15 : 30}  // Use the isMobile state to set maxBars
              />
          </div>
      )}

      {/* pay by skill pairing bar chart */}
      {visibilityFlags.avgSalaryBoostBySkill && (
        <div className="grid-item">
         <GenericBarChart
          data={avgSalaryBoostBySkill}
          dataKey="skill"
          dataValue="percentageDiff"
          title="% Pay Gain by Skill"
          nameFormatter={(skill) => skill || 'Unknown'}
          valueFormatter={(value) => [`Pay Diff: ${Number(value).toPrecision(2)}%`]}
          xAxisAngle = {-45}
          xAxisFontSize = {'10px'}
          yAxisFontSize = {'12px'}
          infoDescription ="Salary difference between job posts with and without the mention of each skill. Only including jobs where kdb+ is a primary skill."
          maxBars={isMobile ? 15 : 30}
        />
        </div>
      )}

      {visibilityFlags.skillCount && (
        <div className="grid-item">
          <GenericBarChartHorizontal
            data={skillCount}
            dataKey="skill"
            dataValue="percentage"
            title="Skills Frequency"
            nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
            valueFormatter={(percentage) => {
              const value = Number(percentage); // Convert to number if it's not already
              const rounded = Number(value.toPrecision(2)); // Round to 3 significant figures
              return `${rounded.toLocaleString()}%`; // Convert to string with percentage sign
            }}
            yAxisFontSize = {'10px'}
            infoDescription ="Occurances of a skill appearing in job posts as a percentage of filtered kdb+ jobs - top 12 results"
          />
        </div>
      )}

      {/* pay by skill pairing bar chart */}
      {visibilityFlags.payBySkillPairing && (
        <div className="grid-item">
         <GenericBarChart
          data={avgPayBySkillPairing}
          dataKey="skill"
          dataValue="avgPay"
          title="Highest paying accompanying Skills"
          nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
          valueFormatter={(value) => `${value.toLocaleString()} ${currency}`}
          xAxisAngle = {-30}
          xAxisFontSize = {'12px'}
          yAxisFontSize = {'12px'}
          infoDescription ="Average advertised pay by skill that appears in a job post alongside kdb+ - excluding skills which < 6 times)"
        />
        </div>
      )}

      {visibilityFlags.payByExperience && (
        <div className="grid-item">
          <GenericLineChart
            data={payByExperience}
            dataKey="experience"
            dataValue="averagePay"
            lines={[{ dataValue: 'averagePay', color: '#8884d8' }]}
            title="Pay by Experience"
            nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
            valueFormatter={(value) => `${value.toLocaleString()}`}
            xAxisFontSize = {'12px'}
            yAxisFontSize = {'12px'}
            infoDescription ="Average advertised pay by experience (years)"
            />
        </div>
      )}

      {visibilityFlags.payBySeniority && (
        <div className="grid-item">
           <GenericBarChart
            data={payBySeniority}
            dataKey="seniority"
            dataValue="averagePay"
            title="Average Pay by Seniority"
            nameFormatter={(currency) => `Adjusted Annual Pay (${currency})`}
            valueFormatter={(value) => `${value.toLocaleString()} ${currency}`}
            sortOrder="asc"
            xAxisFontSize = {'12px'}
            yAxisFontSize = {'12px'}
            infoDescription ="Seniority sometimes assumed based on years experience if not present in the job advert"
          />
        </div>
      )}

      {visibilityFlags.jobsOverTime && (
        <div className="grid-item">
          <GenericLineChart
            data={jobsOverTime}
            dataKey="weekStart"
            lines={[{ dataValue: 'allJobs', color: 'grey' }, { dataValue: 'linkedin', color: 'blue' }, { dataValue: 'indeed', color: 'red' }]}
            title="Job Count over Time"
            nameFormatter={(name) => "name"} // Customize as needed
            valueFormatter={(value) => `${value} jobs`} // Customize as needed
            xAxisFontSize = {'12px'}
            yAxisFontSize = {'12px'}
            infoDescription ="Count of job posts per week (sun-sun). readded jobs counted by most recent date"
            />
        </div>
      )}

      {/* job count by industry pie chart */}
      {visibilityFlags.countByIndustry && (
        <div className="grid-item">
         <GenericPieChart
          data={countByIndustry}
          dataKey="industry"
          dataValue="percentage"
          title="Jobs Count by Industry"
          nameFormatter={(industry) => industry || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
          infoDescription ="Count of job posts by industry"
        />
        </div>
      )}

      {visibilityFlags.countByInstitutionType && (
        <div className="grid-item">
          <GenericBarChartHorizontal
            data={countByInstitutionType}
            dataKey="institutionType"
            dataValue="percentage"
            title="Jobs Count by Institution Type"
            nameFormatter={(institutionType) => institutionType || 'Unknown'}
            valueFormatter={(percentage) => {
              const value = Number(percentage); // Convert to number if it's not already
              const rounded = Number(value.toPrecision(2)); // Round to 3 significant figures
              return `${rounded.toLocaleString()}%`; // Convert to string with percentage sign
            }}
            yAxisFontSize = {'10px'}
            yAxisAngle={-15}
            infoDescription ="Count of job posts by institution type"
          />
        </div>
      )}

      {/* job count by primary/secondary skill pie chart */}
      {visibilityFlags.countByPrimarySecondary && (
        <div className="grid-item">
         <GenericPieChart
          data={countByPrimarySecondary}
          dataKey="skillType"
          dataValue="percentage"
          title="KDB+ as a Primary or Secondary Skill"
          nameFormatter={(skillType) => skillType || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
          infoDescription ="Count of job posts where kdb+ was a requirement vs a 'nice to have'"
        />
        </div>
      )}

      {/* job count by source: employer/recruiter */}
      {visibilityFlags.countBySource && (
        <div className="grid-item">
         <GenericPieChart
          data={countBySource}
          dataKey="type"
          dataValue="percentage"
          title="Jobs Count by Employer vs Recruiter"
          nameFormatter={(type) => type || 'Unknown'}
          valueFormatter={(value, name) => [`${name}: ${Number(value).toPrecision(2)}%`]}
        />
        </div>
      )}
      

    </div>

  );

};

export default ChartsGrid;
