import React, { useEffect, useState, memo, useMemo } from "react";
import axios from "axios";
import { MapContainer, TileLayer, CircleMarker, Tooltip, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { fetchGeoData } from './api';
import './bubbleMap.css';

const BubbleMap = ({ jobs, jobIDs, setJobIDs }) => {

  const scalingFactor = 6; //size of the bubbles

  const handleCircleClick = (event, jobID) => {
    event.originalEvent._stopped = true;  // Mark the event as stopped
    if (JSON.stringify(jobIDs) === JSON.stringify(jobID)) {
      setJobIDs([].concat(...Object.values(groupedJobs).map(items => items.map(item => item.jobID)))); // select all if the same bubble is clicked
    } else {
      setJobIDs(jobID); // Otherwise, set new selection
    }
  };

  //group by location
  const groupedJobs = useMemo(() => {
    const groups = {};
    jobs.forEach((job) => {
      const lat = job.latitude;
      const lon = job.longitude;
      if (lat === null || lon === null || lat === undefined || lon === undefined) {
        return;
      }
      const key = `${lat},${lon}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(job);
    });
    
    // Convert to array, sort, and convert back to object
    const sortedEntries = Object.entries(groups).sort(
      ([, a], [, b]) => a.length - b.length
    );
    return Object.fromEntries(sortedEntries);
  }, [jobs, jobIDs]);

  const handleMapClick = (e) => {
    if (e.target && e.target._leaflet_id === e.sourceTarget._leaflet_id) {
      setJobIDs([].concat(...Object.values(groupedJobs).map(items => items.map(item => item.jobID)))); // select all
    }
  };

  const SelectAllControl = ({ onSelectAll }) => {
    const map = useMap();

    useEffect(() => {
      const control = new L.Control({ position: 'bottomleft' });

      control.onAdd = function () {
        const container = L.DomUtil.create('div', 'leaflet-bar select-all-control');
        const button = L.DomUtil.create('button', 'select-all-button', container);
        button.innerText = 'Select All';
        button.onclick = function(e) {
          L.DomEvent.stopPropagation(e);
          onSelectAll();
        };

        L.DomEvent.disableClickPropagation(container);

        return container;
      };

      control.addTo(map);

      return () => {
        control.remove();
      };
    }, [map, onSelectAll]);

    return null;
  };

  const selectAll = () => {
    setJobIDs([].concat(...Object.values(groupedJobs).map(items => items.map(item => item.jobID))));
  };

  return (
    <MapContainer 
      center={[20, 10]} 
      zoom={1.5} 
      style={{ height: "100%", width: "100%" }}
      whenReady={(e) => {
        e.target.on('click', handleMapClick);  // Attach click event here
      }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {Object.keys(groupedJobs).map((key, index) => {
        const [lat, lon] = key.split(",").map(Number);
        const items = groupedJobs[key];
        const anyJobIDIncluded = items.some((item) => jobIDs.includes(item.jobID));
        const fillColor = anyJobIDIncluded ? "blue" : "grey";
        
        return (
          <CircleMarker
            key={`${lat},${lon}-${fillColor}`}
            center={[lat, lon]}
            color={fillColor}
            weight={0}
            fillColor={fillColor}
            fillOpacity={0.5}
            radius={Math.sqrt(items.length) * scalingFactor}  // Set the radius dynamically
            eventHandlers={{
              click: (event) => handleCircleClick(event, items.map(item => item.jobID)),
            }}
          >
            <Tooltip>{items[0].location}</Tooltip>
          </CircleMarker>
        );
      })}
      <SelectAllControl onSelectAll={selectAll} />
    </MapContainer>
  );
};

export default BubbleMap;
