import React, { useState, useEffect } from 'react';
import { fetchEmployerByCompany, updateEmployerDetails } from '../api';
import ControlJobListingsCheckbox from './ControlJobListingsCheckbox';
import JobGrid from './JobGrid';
import AccessDenied from './AccessDenied';
import EmployerJobsIntro from './EmployerJobsIntro';
import { fetchJobsByCompany } from '../api';
import './EmployersPortal.css';

function CompanyJobsPage({ company, uniqueColumnValues, isSignedIn, user, isPrivileged }) {

  const [directListing, setDirectListing] = useState(true);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Define fetchJobs function to be used within useEffect hooks
  const fetchJobs = async () => {
    if (company) {
      setIsLoading(true); // Start loading
      const jobsByCompany = await fetchJobsByCompany(directListing, company);
      setFilteredJobs(jobsByCompany);
      setIsLoading(false); // End loading
    }
  };

  const refreshJobs = () => {
    fetchJobs();
  };

  // This useEffect now correctly uses the fetchJobs function
  useEffect(() => {
    if (company) {
      fetchJobs();
    }
  }, [company, directListing]); 

  const isValidUserAndCompany = isSignedIn && company && company !== 'n/a' && company !== '';
  if (!isValidUserAndCompany) {
    return <AccessDenied isSignedIn={isSignedIn}/>;
  }

  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <EmployerJobsIntro company={company} directListing={directListing} setDirectListing={setDirectListing} setIsLoading={setIsLoading}/>
      <JobGrid jobs={filteredJobs} directListing={directListing} company={company} uniqueColumnValues={uniqueColumnValues} user={user} refreshJobs={refreshJobs} isPrivileged={isPrivileged} />
    </div>
  );

}

export default CompanyJobsPage;