import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JobCard from '../jobCard'; // Adjust the path as necessary
import useFavouriteJobs from '../utils/manageFavouriteJobs.js';
import FilterComponent from './FilterComponent';
import './mobileJobBoard.css';

function MobileKdbJobs({ jobs, selectedFilters, setSelectedFilters }) {
  const { favouriteJobsList, toggleFavouriteJobForUser } = useFavouriteJobs();
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const [jobLimit, setJobLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [jobsEnriched, setJobsEnriched] = useState([]);

  useEffect(() => {
      const updatedJobs = jobs.map(job => ({
        ...job,
        star: favouriteJobsList.includes(job.jobID), // Check if job is in favouriteJobsList
      }));
      setJobsEnriched(updatedJobs);
    }, [jobs, favouriteJobsList]);


  // Callback function to update selected filters - now directly uses the prop
  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  useEffect(() => {

    const JobsAfterFilter = jobsEnriched.filter(job => {
      let isLocationMatch = true, isSeniorityMatch = true, isWorkArrangementMatch = true, isTypeMatch = true, isWorkCompanyMatch = true;
      let isSkillsMatch = selectedFilters.skills?.length ? false : true;

      if (selectedFilters.location?.length) {
        isLocationMatch = selectedFilters.location.some(filter =>
          filter.value === job.country || filter.value === job.location);
      }
      if (selectedFilters.seniority?.length) {
        isSeniorityMatch = selectedFilters.seniority.some(filter => filter.value === job.seniority);
      }
      if (selectedFilters.workArrangement?.length) {
        isWorkArrangementMatch = selectedFilters.workArrangement.some(filter => filter.value === job.workArrangement);
      }
      if (selectedFilters.type?.length) {
        isTypeMatch = selectedFilters.type.some(filter => filter.value === job.employmentType);
      }
      if (selectedFilters.company?.length) {
        isWorkCompanyMatch = selectedFilters.company.some(filter => filter.value === job.company);
      }
      if (selectedFilters.skills?.length) {
        isSkillsMatch = selectedFilters.skills.every(filter =>
          job.primarySkills?.includes(filter.value) || job.secondarySkills?.includes(filter.value));
      }

      return isLocationMatch && isSeniorityMatch && isWorkArrangementMatch && isTypeMatch && isWorkCompanyMatch && isSkillsMatch;
    });

    const sortedActiveJobs = JobsAfterFilter.sort((a, b) => {
      // Sort by 'maxPay' (assuming you want to keep this as an example, even though it's not mentioned in your update request)
      if (selectedFilters.sort === 'maxPay') {
        return b.maxPay - a.maxPay; // Assuming maxPay is a number
      }
      // Sort by 'added' date in descending order
      else if (selectedFilters.sort === 'added') {
        return new Date(b.added) - new Date(a.added);
      }
      // Sort by 'company' in ascending order
      else if (selectedFilters.sort === 'company') {
        return a.company.localeCompare(b.company);
      }
      // Default case if none of the above (optional, can remove if not needed)
      else {
        return 0;
      }
    });

    setDisplayedJobs(sortedActiveJobs.slice(0, jobLimit));
  }, [jobsEnriched, jobLimit, selectedFilters]);

  useEffect(() => {
      const handleScroll = () => {
          const tolerance = 100;
          const distanceFromBottom = 1*document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop);

          if (distanceFromBottom > tolerance || isLoading || jobLimit >= 50) return;

          setIsLoading(true);
          setTimeout(() => {
              setJobLimit(prevLimit => prevLimit >= 45 ? 50 : prevLimit + 5);
              setIsLoading(false);
          }, 2000);
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, jobLimit]); // This remains unchanged

  const navigate = useNavigate(); // Use useNavigate hook
  const handleJobClick = (jobID) => {
    navigate(`/job/${jobID}`); // Navigate to the job route
  };

  return (
    <div className="page">
      <div className="intro-container-mobile">
        <h1 className="intro-mobile">Uncover Global Opportunities in the World of KDB+</h1>
      </div>
      <div className="job-cards-container">
        <FilterComponent data={jobs} selectedFilters={selectedFilters} onFilterChange={handleFilterChange} />
        {displayedJobs.map(job => (
          <div key={job.id}>
            <JobCard job={job} maxSkills={6} showDate={true} skillOrder={selectedFilters.skills.map(skill => skill.value)} onStarClick={toggleFavouriteJobForUser} handleCardClick={() => handleJobClick(job.jobID)}/>
          </div>
        ))}
        {isLoading && <div className="loading-indicator">Loading...</div>}
      </div>
    </div>
  );
}

export default MobileKdbJobs;