import React, { useState } from 'react';
import './TypeAheadDropdown.css';

function TypeAheadDropdown({ options, value, placeholder, onChange, onSelect }) {
  const [inputValue, setInputValue] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsDropdownVisible(true);
    // Safely call onChange only if it's provided
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleOptionSelect = (val) => {
    setInputValue(val);
    setIsDropdownVisible(false);
    if (onChange) {
      onChange(val);
    }
    if (onSelect) {
      onSelect(val);
    }
  };

  const filteredOptions = options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase()));

  return (
    <div className="typeahead-dropdown">
      <input
        type="text"
        value={value || inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsDropdownVisible(true)}
        onBlur={() => setTimeout(() => setIsDropdownVisible(false), 100)}
        placeholder={placeholder}
        className="form-input"
      />
      {isDropdownVisible && filteredOptions.length > 0 && (
        <ul className="dropdown-list">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionSelect(option)}
              onMouseDown={(e) => {
                e.preventDefault(); // Prevents the input from losing focus
                handleOptionSelect(option);
              }}
              className="dropdown-option"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TypeAheadDropdown;
