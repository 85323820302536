import React, { useState, useEffect } from 'react';
import './analysisPage.css';
import { convertCurrency } from '../currencyExchange.js';
import ChartsGrid from './chartsGrid.jsx';
import MailchimpForm from '../utils/MailchimpForm';
import { Helmet } from 'react-helmet';


import { TriggerFilterEvent } from '../utils/pageTracking.jsx';

const AnalysisPage = ({ originalJobData, currency }) => {

  const [jobData, setJobData] = useState(originalJobData);
  const [filteredJobData, setFilteredJobData] = useState(null);

  const [country, setCountry] = useState("All Countries");
  const [seniority, setSeniority] = useState("All Seniorities");
  const [employmentType, setEmploymentType] = useState("All Employment Types");
  const [workArrangement, setWorkArrangement] = useState("All Work Arrangements");
  const [timespan, setTimespan] = useState("Past 3 Months");

  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [uniqueSeniorities, setUniqueSeniorities] = useState([]);
  const [uniqueEmploymentTypes, setUniqueEmploymentTypes] = useState([]);
  const [uniqueWorkArrangements, setUniqueWorkArrangements] = useState([]);
  const timespans = ["Incl Historic Listings", "Past 3 Months", "Active Listings Only"];

  const[jobCount, setJobCount] = useState(0);

  //scroll panel behaviour

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [direction, setDirection] = useState('up');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setDirection(currentScrollY > lastScrollY ? 'down' : 'up');
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);


  // visibility flags 

  const [visibilityFlags, setVisibilityFlags] = useState({
    countByCountry: true,
    countByPrimarySecondary: true,
    countByEmploymentType: true,
    countByWorkArrangement: true,
    countByJobTitle: true,
    avgPayByCountry: true,
    skillCount: true,
    payBySkillPairing: false,
    payByExperience: false,
    payBySeniority: false,
    jobsOverTime: false,
    countByCompany: true,
    avgSalaryBoostBySkill: true,
    countByIndustry: true,
    countByInstitutionType:true,
    countBySource:true,
  });

  const setSpecificFlag = (flagName, value) => {
    setVisibilityFlags(prevFlags => ({
      ...prevFlags,
      [flagName]: value
    }));
  };


  // generate chart options

  useEffect(() => {
    if (originalJobData) {
      const countryCounts = {};
      const seniorityCounts = {};
      const employmentTypeCounts = {};
      const workArrangementCounts = {};

      originalJobData.forEach(job => {
        if (job.country) countryCounts[job.country] = (countryCounts[job.country] || 0) + 1;
        if (job.seniority) seniorityCounts[job.seniority] = (seniorityCounts[job.seniority] || 0) + 1;
        if (job.employmentType) employmentTypeCounts[job.employmentType] = (employmentTypeCounts[job.employmentType] || 0) + 1;
        if (job.workArrangement) workArrangementCounts[job.workArrangement] = (workArrangementCounts[job.workArrangement] || 0) + 1;
      });

      const validValue = value => value && value.toLowerCase() !== 'none';

      const countries = Object.keys(countryCounts)
                             .filter(country => validValue(country) && countryCounts[country] > 5)
                             .sort();
      const seniorities = Object.keys(seniorityCounts)
                                .filter(seniority => validValue(seniority) && seniorityCounts[seniority] > 5)
                                .sort();
      const employmentTypes = Object.keys(employmentTypeCounts)
                                    .filter(employmentType => validValue(employmentType) && employmentTypeCounts[employmentType] > 5)
                                    .sort();
      const workArrangements = Object.keys(workArrangementCounts)
                                     .filter(workArrangement => validValue(workArrangement) && workArrangementCounts[workArrangement] > 5)
                                     .sort();

      setUniqueCountries(['All Countries', ...countries]);
      setUniqueSeniorities(['All Seniorities', ...seniorities]);
      setUniqueEmploymentTypes(['All Employment Types', ...employmentTypes]);
      setUniqueWorkArrangements(['All Work Arrangements', ...workArrangements]);
    }
  }, [originalJobData]);

  // When currency changes, use the cached original data to perform the conversion
  useEffect(() => {
    if (originalJobData && currency) {
      const updatedData = originalJobData.map(job => ({
        ...job,
        maxPay: convertCurrency(job.maxPay, job.currency, currency),
        minPay: convertCurrency(job.minPay, job.currency, currency),
        maxSalary: convertCurrency(job.maxSalary, job.currency, currency),
        minSalary: convertCurrency(job.minSalary, job.currency, currency)
      }));

      setJobData(updatedData);
    }
  }, [currency, originalJobData]);

  const handleTimespanChange = (e) => {
    setTimespan(e.target.value);
  };

  useEffect(() => {
    if (!jobData || jobData.length === 0) {
      return;
    }

    let filteredData = jobData;

    const now = new Date();
    filteredData = filteredData.filter((job) => {
      switch (timespan) {
        case "Past 3 Months": // Update the case name if needed
          let ninetyDaysAgo = new Date(now);
          ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90); // Subtract 90 days
          return new Date(job.added) >= ninetyDaysAgo;
        case "Active Listings Only":
          return job.active; // Assumes job.active is a boolean
        case "Incl Historic Listings":
        default:
          return true; // Include all jobs
      }
    });

    if (country === 'All Countries') {
      //setSpecificFlag('countByCountry', true);
      //setSpecificFlag('avgPayByCountry', true);
    } else {
      filteredData = filteredData.filter(job => job.country === country);
      //setSpecificFlag('countByCountry', false);
      //setSpecificFlag('avgPayByCountry', false);
    }

    if (seniority === 'All Seniorities') {
      //setSpecificFlag('payBySeniority', true);
    } else {
      filteredData = filteredData.filter(job => job.seniority === seniority);
      //setSpecificFlag('payBySeniority', false);
    }

    if (employmentType === 'All Employment Types') {

    } else {
      filteredData = filteredData.filter(job => job.employmentType === employmentType);
    }

    if (workArrangement === 'All Work Arrangements') {

    } else {
      filteredData = filteredData.filter(job => job.workArrangement === workArrangement);
    }

    setFilteredJobData(filteredData);
    setJobCount(filteredData.length);

  }, [jobData, timespan, country, seniority, employmentType, workArrangement]);


  useEffect(() => {
    TriggerFilterEvent({ filter: 'country', value: country });
  }, [country]);
  useEffect(() => {
    TriggerFilterEvent({ filter: 'seniority', value: seniority });
  }, [seniority]);
  useEffect(() => {
    TriggerFilterEvent({ filter: 'employmentType', value: employmentType });
  }, [employmentType]);
  useEffect(() => {
    TriggerFilterEvent({ filter: 'workArrangement', value: workArrangement });
  }, [workArrangement]);


///////////// html /////////////
  return (

    <div>

    <Helmet>
      <title>KDB+ Job Market Analysis - Dive Deep into the Kdb+ Job Market</title>
      <meta name="description" content="Explore comprehensive Kdb+ job market analysis. Discover trends, salaries, and opportunities in the Kdb+ ecosystem to enhance your career." />
      <meta property="og:title" content="KDB+ Job Market Analysis - Uncover Kdb+ Career Insights" />
      <meta property="og:description" content="Get the latest insights and trends in the Kdb+ job market. Analyze opportunities, salaries, and demand in the Kdb+ industry." />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${process.env.PUBLIC_URL}/preview_images/kdbMarketAnalysis.png`} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>

      <div className="intro-container-analysis">
        <div className="intro-analysis">KDB+ Job Market Analysis</div>
        <div className="intro-container-analysis-2">
          <div className="intro-analysis-2">Real-time insights to power your job search</div>
        </div>
      </div>

      <div className="outer-container">

        <div className={`panel ${direction === 'down' ? 'hide' : ''}`}>

          <div className="dropdown-container">

            <select className="dropdown" value={timespan} onChange={handleTimespanChange}>
              {timespans.map((timeOption, index) => (
                <option key={index} value={timeOption}>
                  {timeOption}
                </option>
              ))}
            </select>

            <select className="dropdown" value={country} onChange={(e) => setCountry(e.target.value)}>
              {uniqueCountries.map((countryName, index) => (
                <option key={index} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>

            <select className="dropdown" value={seniority} onChange={(e) => setSeniority(e.target.value)}>
              {uniqueSeniorities.map((seniority, index) => (
                <option key={index} value={seniority}>
                  {seniority}
                </option>
              ))}
            </select>

            <select className="dropdown" value={employmentType} onChange={(e) => setEmploymentType(e.target.value)}>
              {uniqueEmploymentTypes.map((employmentType, index) => (
                <option key={index} value={employmentType}>
                  {employmentType}
                </option>
              ))}
            </select>

            <select className="dropdown" value={workArrangement} onChange={(e) => setWorkArrangement(e.target.value)}>
              {uniqueWorkArrangements.map((workArrangement, index) => (
                <option key={index} value={workArrangement}>
                  {workArrangement}
                </option>
              ))}
            </select>

          </div>

          <div className="jobCount">Viewing {jobCount} jobs</div>

        </div>

        <ChartsGrid jobData={filteredJobData} currency={currency} visibilityFlags={visibilityFlags}/>

      </div>

      <div className="intro-container">
        <MailchimpForm />
      </div>

    </div>
  );

};

export default AnalysisPage;
