import React, { useState } from 'react';
import FilterDropdown from './FilterDropdown';
import FilterSelectionDisplay from './FilterSelectionDisplay'; // Import the new component
import Switch from './Switch'
import './FilterComponent.css';

const FilterComponent = ({ data, selectedFilters, onFilterChange }) => {

const resetFilters = () => {
    const resetState = {
      location: [],
      seniority: [],
      workArrangement: [],
      type: [],
      company: [],
      skills: [],
      sort: sortOption
      // Do not reset 'sort' here if you're managing it separately or want it to persist
    };
    // Now, explicitly pass both filters and sort preference to the parent component
    onFilterChange({ ...resetState});
  };


  // Handler to append a new selection
  const handleSelectionChange = (filterType, selectedOption) => {
    if (selectedOption && !selectedFilters[filterType].find(option => option.value === selectedOption.value)) {
      const newFilters = {
        ...selectedFilters,
        [filterType]: [...selectedFilters[filterType], selectedOption],
      };
      onFilterChange(newFilters); // Notify the parent component of the filter change
    }
  };

  const handleRemoveFilter = (filterType, selectedOption) => {
    const filteredOptions = selectedFilters[filterType].filter(option => option.value !== selectedOption.value);
    const newFilters = {
      ...selectedFilters,
      [filterType]: filteredOptions,
    };
    onFilterChange(newFilters);
  };

  const getUniqueOptionsWithCount = (data, keys) => {
    const excludeList = ['None', 'none', 'null', 'Null', '']; // Add empty string to list
    const counts = data.reduce((acc, item) => {
      keys.forEach(key => {
        const value = item[key];
        // Check if value is not in excludeList, and is not null or undefined
        if (!excludeList.includes(value) && value !== null && value !== undefined) {
          acc[value] = (acc[value] || 0) + 1;
        }
      });
      return acc;
    }, {});

    // Convert to array, combine, sort in descending order of count, then map to desired format
    return Object.entries(counts)
      .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
      .map(([value, count]) => ({
        value: value,
        label: `${value}`,
        //label: `${value} (${count})`,
      }));
  };

  // Generate unique options with count
  const getUniqueSkillsOptions = (data) => {
    let skills = [];

    data.forEach(item => {
      let primarySkillsArray = [];
      let secondarySkillsArray = [];

      // Parse JSON strings into arrays
      if (typeof item.primarySkills === 'string') {
        try {
          primarySkillsArray = JSON.parse(item.primarySkills);
        } catch (e) {
          console.error('Error parsing primarySkills:', e);
        }
      }

      if (typeof item.secondarySkills === 'string') {
        try {
          secondarySkillsArray = JSON.parse(item.secondarySkills);
        } catch (e) {
          console.error('Error parsing secondarySkills:', e);
        }
      }

      // Filter out "Kdb+/q" from the arrays
      primarySkillsArray = primarySkillsArray.filter(skill => skill !== "Kdb+/q");
      secondarySkillsArray = secondarySkillsArray.filter(skill => skill !== "Kdb+/q");

      // Ensure they are arrays after parsing and filtering
      if (Array.isArray(primarySkillsArray) && Array.isArray(secondarySkillsArray)) {
        skills = skills.concat(primarySkillsArray.concat(secondarySkillsArray));
      }
    });

    // Remove duplicates and count
    const counts = skills.reduce((acc, skill) => {
      acc[skill] = (acc[skill] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(counts).map(([value, count]) => ({
      value: value,
      label: `${value}`,
      //label: `${value} (${count})`,
    }));
  };

  const locationOptions = getUniqueOptionsWithCount(data, ['country','location']);
  const seniorityOptions = getUniqueOptionsWithCount(data, ['seniority']);
  const workArrangementOptions = getUniqueOptionsWithCount(data, ['workArrangement']);
  const typeOptions = getUniqueOptionsWithCount(data, ['employmentType']);
  const companyOptions = getUniqueOptionsWithCount(data, ['company']);
  const skillsOptions = getUniqueSkillsOptions(data);
  const [sortOption, setSortOption] = useState('added');

  const handleSortChange = (selectedOption) => {
    const updatedFilters = { ...selectedFilters, sort: selectedOption };
    onFilterChange(updatedFilters);
  };

  // Convert selected filters into a displayable format
  const filterSelectionDisplay = Object.entries(selectedFilters).flatMap(([key, values]) => 
    Array.isArray(values) ? values.map(value => `${key}: ${value.label}`) : []).join(', ');

  return (
    <div className='filter-component'>
      <FilterSelectionDisplay selectedFilters={selectedFilters} onClearFilters={resetFilters} onRemoveFilter={handleRemoveFilter} includeKdb={true}/>
      <div className="filter-container">
        <FilterDropdown label="Location" options={locationOptions} onChange={(selectedOption) => handleSelectionChange('location', selectedOption)} />
        <FilterDropdown label="Seniority" options={seniorityOptions} onChange={(selectedOption) => handleSelectionChange('seniority', selectedOption)} />
        <FilterDropdown label="Work From" options={workArrangementOptions} onChange={(selectedOption) => handleSelectionChange('workArrangement', selectedOption)} />
        <FilterDropdown label="Type" options={typeOptions} onChange={(selectedOption) => handleSelectionChange('type', selectedOption)} />
        <FilterDropdown label="Company" options={companyOptions} onChange={(selectedOption) => handleSelectionChange('company', selectedOption)} />
        <FilterDropdown label="Skills" options={skillsOptions} onChange={(selectedOption) => handleSelectionChange('skills', selectedOption)} />
      </div>
      <div className="sorting-container">
        <Switch onSwitchChange={handleSortChange} />
      </div>
    </div>
  );
};

export default FilterComponent;