import React from 'react';
import './ChangesReview.css'; // Adjust the import path as necessary

const SaveReminder = ({ jobsStatusBefore, jobsStatusAfter }) => {
  // Function to compare job statuses
  const compareJobStatuses = () => {
    if (jobsStatusBefore.length !== jobsStatusAfter.length) {
      return false; // Different sizes mean they're automatically different
    }

    for (let i = 0; i < jobsStatusBefore.length; i++) {
      const before = jobsStatusBefore[i];
      const after = jobsStatusAfter.find(afterItem => afterItem.jobID === before.jobID);
      // If a matching job is not found, or statuses don't match, return false
      if (!after || before.expires !== after.expires || before.active !== after.active) {
        return true;
      }
    }
    return false; // All jobs matched
  };

  const hasChanges = compareJobStatuses();

  return (
    <div className={`changes-review ${hasChanges ? 'has-changes' : ''}`}>
      <p>
        {hasChanges
          ? <><strong>Unconfirmed Changes!</strong> Confirm at the bottom of the page.</>
          : <>After adding a job, remember to <strong>activate</strong> it and <strong>confirm activation</strong> at the bottom of the page.</>}
      </p>
    </div>
  );
};

export default SaveReminder;
