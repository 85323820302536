import React from 'react';
import ControlJobListingsCheckbox from './ControlJobListingsCheckbox';
import './EmployerJobsIntro.css';

const EmployerJobsIntro = ({ company, directListing, setDirectListing, setIsLoading }) => {
    const today = new Date();
    const maxActivationDate = new Date(today);
    maxActivationDate.setDate(today.getDate() + 60); // Adds 60 days to the current date

    return (
        <div className="job-portal-intro">

            <div className="about-section">
                <div className="content-wrapper">
                    <div className="content-text">
                        <p>Thanks for signing up as a representative for <strong>{company}</strong>!</p>
                        <p>You are now signed in and can manage your company's job listings directly.</p>
                        <p><strong>Take control of your recruitment process below!</strong> Review and adjust your job postings to find the perfect candidates.</p>
                        <p>Please note, a job can only be activated for a maximum of <strong>60 days</strong> from today's date.</p>
                        <p>Changes made may take up to <strong>10 minutes</strong> to be reflected in the application.</p>
                    </div>
                </div>
            </div>

            <ControlJobListingsCheckbox company={company} directListing={directListing} setDirectListingState={setDirectListing} setIsLoading={setIsLoading} />

        </div>
    );
};

export default EmployerJobsIntro;
