import React, { useEffect, useState } from 'react';
import { Route, Link, Routes } from 'react-router-dom';

import './App.css';
import { fetchData_one, fetchData_two } from './api';
import JobMapPage from './jobMapPage.js';
import AnalysisPage from './analysisPage/analysisPage.jsx';
import About from './about/About.jsx';
import MasonryPage from './masonry/Masonry.jsx';
import ResourceList from './resources/ResourceList.jsx';
import MobileKdbJobs from './mobile/mobileJobBoard';
import JobPage from './jobPage/JobPage';
import PrivacyPolicy from './policies/PrivacyPolicy.jsx';
import CookieConsentComponent from './policies/CookieConsentComponent.jsx';
import CurrencySelector, { getLocalCurrency } from './currencySelector';
import TopBar from './TopBar_mui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EmployersPortal from './employersPortal/EmployersPortal';
import './utils/pageTracking.jsx';
import initHotjar from './utils/initHotjar';


function App() {
  
  const [isActive, setIsActive] = useState(false);
  const [currency, setCurrency] = useState(getLocalCurrency());
  const [jobs_active, setJobsActive] = useState([]);
  const [jobs_analysis, setJobsAnalysis] = useState([]);

  //hiding site
  const [showMessage, setShowMessage] = useState(false);
  const toggleDisplay = () => {
    setShowMessage(!showMessage);
  };

  //get job data
  useEffect(() => {
    const getJobs = async () => {
      // Fetch first part of the data
      const responseDataOne = await fetchData_one();
      if (Array.isArray(responseDataOne)) {
        setJobsActive(responseDataOne); // Set jobs_active with the first part of the data
      } else {
        console.error("Received first part of data is not an array:", responseDataOne);
      }

      // Fetch second part of the data
      const responseDataTwo = await fetchData_two();
      if (Array.isArray(responseDataTwo)) {
        setJobsAnalysis(responseDataTwo); // Set jobs_analysis with the second part of the data
      } else {
        console.error("Received second part of data is not an array:", responseDataTwo);
      }
    };
    getJobs();
    //initHotjar();
  }, []);

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  //filters for mobile job search page
  const [selectedFilters, setSelectedFilters] = useState({
    location: [],
    seniority: [],
    workArrangement: [],
    company: [],
    type: [],
    skills: [],
    sort: 'added'
  });

  return (
    <>
      <div className="App">
        <div className="mobile-message">
          <h1>Kdb+Jobs</h1>
          <p>Not yet available on mobile</p>
        </div>
        
        {showMessage ? (
          <div className="site-down-message">
            <h1>Kdb+Jobs</h1>
            <p>The site is down for planned maintainance.</p>
            <p>Please try again shortly.</p>
            <p>Thanks for visiting!</p>
            <button className="access-button" onClick={toggleDisplay}>Admin</button>
          </div>
        ) : (
          <>
            <TopBar setCurrency={setCurrency} />

            <Routes>
              <Route
                path="/"
                element={isMobileDevice() ? 
                  <MobileKdbJobs jobs={jobs_active.filter(job => job.active)} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} /> : 
                  <JobMapPage jobs={jobs_active.filter(job => job.active)} currency={currency} />
                }
              />
              <Route path="/analysis" element={<AnalysisPage originalJobData={jobs_analysis} currency={currency} />} />
              <Route path="/about" element={<About />} />
              <Route path="/feed" element={<MasonryPage />} />
              <Route path="/resources" element={<ResourceList />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/job/:jobID" element={<JobPage />} />
              <Route path="/employers/*" element={<EmployersPortal />} />
            </Routes>
          </>
        )}

        <div className="info-bar">
          <Link to="/about">About</Link>
          <Link to="/resources">Resources</Link>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </div>

      </div>

      <CookieConsentComponent />
    </>

  );

}

export default App;
