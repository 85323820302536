import React from 'react';
import './AccessDenied.css';

const AccessDenied = ({ isSignedIn }) => {

  return (
    <div className="about-page">
      <div className="intro-container">
        <div className="intro">Welcome to the Employers Portal!</div>
      </div>
      <div className="about-section">
        <div className="content-wrapper">
          {isSignedIn ? (
            <>
              <div className="content-text-1">
                <p>To access the employer's dashboard, you must be an authorized user for your company.</p>
              </div>
              <div className="content-text-2">
                <p>
                  Please send an email to will@leftjoindata.com from your company email to request access.
                </p>
              </div>
              <div className="content-text-3">
                <p>Employer's Portal is not available on mobile</p>
              </div>
            </>
          ) : (
            <>
              <div className="content-text-1">
                <p>A place for employers and recruiters to manage their job posts!</p>
              </div>
              <div className="content-text-2">
                <p>You must be logged in to continue</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
