import React from 'react';
import './FilterSelectionDisplay.css';
import JobProperty from '../jobProperty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const FilterSelectionDisplay = ({ selectedFilters, onClearFilters, onRemoveFilter, includeKdb = false }) => {
  // Remove the sort field from selectedFilters
  const filtersWithoutSort = {...selectedFilters};
  delete filtersWithoutSort.sort;

  const handleFilterClick = (key, value) => {
    onRemoveFilter(key, value);
  };

  // Generate the filter components from selectedFilters, excluding the sort filter
  const filterComponents = Object.entries(filtersWithoutSort).flatMap(([key, values]) =>
    values.map(value => {
      // Check if value is a string; if so, use it directly. Otherwise, use value.label
      const displayValue = typeof value === 'string' ? value : value.label.replace(/ \(\d+\)$/, '');
      return (
        <JobProperty 
          key={`${key}-${displayValue}`}
          category="filter" 
          value={displayValue}
          showCloseIcon={true}
          onClick={() => handleFilterClick(key, value)}
        />
      );
    })
  );

  // Always include a JobProperty for 'Kdb+' regardless of the contents of selectedFilters
    let kdbFilterComponent = null;
    if (includeKdb) {
      kdbFilterComponent = (
        <JobProperty
          key="kdbFilter-Kdb+"
          category="kdbFilter"
          value="Kdb+/q"
          showCloseIcon={false}
          onClick={() => {}}
        />
      );
    }

  // Adding the kdbFilterComponent to the list of filter components
  filterComponents.push(kdbFilterComponent);

  // Check if there are no selected filters (not counting the always-present Kdb+ filter)
  if (filterComponents.length === 1) { // Only the kdbFilterComponent exists
    return (
      <div className="filter-selection-display">
        {kdbFilterComponent}
      </div>
    );
  }

  return (
    <div className="filter-selection-display">
      <div className="filter-components">{filterComponents}</div>
      {Object.values(filtersWithoutSort).some(arr => arr.length) && (
        <button onClick={onClearFilters} className="clear-filters-btn">
          <span className="close-icon"><FontAwesomeIcon icon={faTimes} /></span>
        </button>
      )}
    </div>
  );
};

export default FilterSelectionDisplay;
