import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './jobProperty.css'; // Import the CSS file

class JobProperty extends Component {
    styles = {
        location: {
            default: { backgroundColor: '#f0f6fb' },
        },
        company: {
            default: { backgroundColor: 'white' },
        },
        workArrangement: {
            office: { color: 'white', backgroundColor: '#5D2E8C' },
            hybrid: { color: 'white', backgroundColor: '#2EC4B6' },
            remote: { color: 'white', backgroundColor: '#FFCC66' },
            default: { color: 'white', backgroundColor: '#888888' },
        },
        employmentType: {
            permanent: { color: 'white', backgroundColor: '#FF6666' },
            contract: { color: 'white', backgroundColor: '#FF66CC' },
            default: { color: 'white', backgroundColor: '#888888' },
        },
        seniority: {
            intermediate: { color: 'white', backgroundColor: '#10B57F' },
            senior: { color: '#006666', backgroundColor: '#66FFFF' },
            junior: { color: 'white', backgroundColor: '#F89820' },
            default: { color: 'white', backgroundColor: '#888888' },
        },
        pay: {
            default: { color:'#2282AC', border: '1px solid #2282AC' },
        },
        experience: {
            default: { color: 'white', backgroundColor: '#2897c7' },
        },
        primarySkill: {
            default: { color: '#5D4C15', backgroundColor: '#E8D69B' },
        },
        secondarySkill: {
            default: { color: '#454545', backgroundColor: '#CBCBCB' },
        },
        filter: {
            default: { color: 'white', backgroundColor: '#2897c7' },
        },
        kdbFilter: {
            default: { color: '#606060', border: '1px solid #606060' },
        },
        status_grey: {
        default: { color: '#DDDDDD' },
        },
        date: {
            default: { color: '#606060'}
        },
        default: {}
    };

    prefixes = {};
    suffixes = {
        experience: '+ years exp'
    };

    getPrefix(category) {
        return this.prefixes[category] || '';
    }

    getSuffix(category) {
        return this.suffixes[category] || '';
    }

    getStyle(category, value) {
        const categoryStyles = this.styles[category];
        if (categoryStyles) {
            // Check if value is not null or undefined before converting to lower case
            const lowerCaseValue = value != null ? value.toString().toLowerCase() : '';
            const modifiedCategoryStyles = Object.keys(categoryStyles).reduce((acc, key) => {
                acc[key.toLowerCase()] = categoryStyles[key];
                return acc;
            }, {});

            return modifiedCategoryStyles[lowerCaseValue] || categoryStyles.default;
        }
        return this.styles.default;
    }

    getFormattedValue(category, value) {
        // For non-date categories, ensure value is not null or undefined before converting to lower case
        if (category === 'date' && value != null) {
            const valueDate = new Date(value.replace(/\./g, '-'));
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            // Format today and yesterday for comparison
            const format = date => date.toISOString().split('T')[0];

            if (format(valueDate) === format(today)) {
                return 'today';
            } else if (format(valueDate) === format(yesterday)) {
                return 'yesterday';
            }
        } else {
            // Convert value to lower case if it's not null or undefined
            value = value != null ? value.toString().toLowerCase() : '';
        }
        return value; // Return original value (or an empty string if null/undefined) for non-date categories
    }
    
    render() {
        const { category, value, moreInfo, showCloseIcon, onClick } = this.props;
        const formattedValue = this.getFormattedValue(category, value); // Get formatted or original value
        const style = this.getStyle(category, formattedValue); // Use formattedValue for style for consistency
        const prefix = this.getPrefix(category);
        const suffix = this.getSuffix(category);

        return (
            <div className="job-property" style={style} onClick={onClick}>
                {prefix}{formattedValue}{suffix} {/* Use formattedValue here */}
                {moreInfo && (
                    <span className="info-icon" data-tip={moreInfo}>
                        <FontAwesomeIcon className="question-icon" icon={faCircleQuestion} color="#606060" />
                        <span className="info-tooltip">{moreInfo}</span>
                    </span>
                )}
                {showCloseIcon && (
                    <span className="close-icon">
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                )}
            </div>
        );
    }
}

export default JobProperty;
