import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTimes, faStar as faStarSolid, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular, faBookmark as faBookmarkRegular} from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import JobProperty from './jobProperty.jsx';
import './jobCard.css';
import { useNavigate } from 'react-router-dom';
import {incrementLinkClick} from './api.js';

const JobCard = ({ job, maxSkills, showDate, showLinks, showSummary, skillOrder = [], showSeeMoreButton = false, showSkillsLegend = false, onEdit, onDelete, onStarClick = null, handleCardClick=null }) => {
  const [copyFeedback, setCopyFeedback] = useState('');
  const [emailPopup, setEmailPopup] = useState({ show: false, email: '' });
  const navigate = useNavigate(); // Corrected: useNavigate is called at the top level
  if (job == null) {
    console.log("NULL JOBCARD");
    return <div className="job-card">Please select a job</div>;
  }

  const {
    jobID,
    link,
    email,
    whatsapp,
    company,
    jobTitle,
    location,
    added,
    seniority,
    employmentType,
    minPay,
    maxPay,
    payInterval,
    currency,
    workArrangement,
    experience,
    primarySkills,
    secondarySkills,
    description_summary,
    star
  } = job;

  const formatDate = (dateString) => {
    // Assuming dateString is in the format "YYYY-MM-DD" or "YYYY-MM-DDTHH:MM:SSZ"
    const parts = dateString.split('T')[0].split('-'); // Split by 'T' to ignore time part and then by '-' to get date parts
    const year = parts[0];
    const month = parts[1]; // Month is already in the correct format (MM)
    const day = parts[2];
    return `${year}.${month}.${day}`; // Return the formatted date string
  };

  const primarySkillsArray = JSON.parse(primarySkills);
  const secondarySkillsArray = JSON.parse(secondarySkills);

  let combinedSkills = [...primarySkillsArray.map(skill => ({ skill, type: 'primarySkill' })), 
                        ...secondarySkillsArray.map(skill => ({ skill, type: 'secondarySkill' }))];

  skillOrder = ["Kdb+/q", ...skillOrder];
  if (skillOrder && skillOrder.length > 0) {
    // Create a copy of combinedSkills to manipulate order
    let orderedSkills = [];

    // Add skills in the order specified by skillOrder
    skillOrder.forEach(orderSkill => {
      let foundIndex = combinedSkills.findIndex(({ skill }) => skill === orderSkill);
      if (foundIndex !== -1) {
        orderedSkills.push(combinedSkills[foundIndex]);
        combinedSkills.splice(foundIndex, 1); // Remove the added skill from combinedSkills
      }
    });

    // Append remaining skills that were not specified in skillOrder
    orderedSkills = [...orderedSkills, ...combinedSkills];
    combinedSkills = orderedSkills; // Set the combinedSkills to the newly ordered list
  }

  combinedSkills = combinedSkills.slice(0, maxSkills); // Limit the skills based on maxSkills

  const handleLinkClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleSeeMoreClick = () => {
    if (job.jobID) {
      const jobUrl = `/job/${job.jobID}`;
      window.open(jobUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const copyToClipboard = (text, callback) => {
    navigator.clipboard.writeText(text).then(() => {
      callback(); // Call the callback function when the text has been successfully copied
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const renderLinkButtons = (linkString, email, whatsapp) => {

      if (showLinks){

        let urls = linkString.split(', ').filter(url => url.trim() !== '');
        if (email) urls.push(`email:${email}`);
        if (whatsapp) urls.push(`https://wa.me/${whatsapp.replace(/\D/g, '')}`);

        if (urls.length === 0) {
          return null;
        }

        return (
          <>
            {urls.map((url, index) => {
              let buttonContent, buttonClass, handleClick;
              url = url.trim();
              if (url.includes('linkedin')) {
                buttonContent = 'LinkedIn';
                buttonClass = 'linkedin-btn apply-btn';
              } else if (url.includes('indeed')) {
                buttonContent = 'Indeed';
                buttonClass = 'indeed-btn apply-btn';
              } else if (url.includes('glassdoor')) {
                buttonContent = 'Glassdoor';
                buttonClass = 'glassdoor-btn apply-btn';
              } else if (url.startsWith('email:')) {
                buttonContent = 'Email';
                buttonClass = 'email-btn apply-btn';
                handleClick = () => {
                  setEmailPopup({ show: true, email: url.slice(6) });
                };
              } else if (url.startsWith('https://wa.me/')) {
                  buttonContent = <img src={`${process.env.PUBLIC_URL}/whatsapp.png`} alt="WhatsApp" style={{ width: '35px', height: '35px' }} />;
                  buttonClass = 'whatsapp-btn';
                  handleClick = () => {
                    window.open(url, '_blank');
                    incrementLinkClick(job.jobID); // Call the API to increment link click count
                  };
              } else {
                if (url) {
                  buttonContent = 'Apply Direct';
                  buttonClass = 'direct-btn apply-btn';
                } else {
                  return null;
                }
              }
              return (
                <button
                  key={index}
                  onClick={handleClick || (() => {
                    handleLinkClick(url);
                    incrementLinkClick(job.jobID); // Call the API to increment link click count
                  })}
                  className={buttonClass}
                >
                  {buttonContent}
                </button>
              );
            }).filter(Boolean)}
            {emailPopup.show && (
              <div className="email-popup"> {/* Add your CSS */}
                {emailPopup.email}
                <button onClick={() => {copyToClipboard(emailPopup.email, () => setEmailPopup({ show: false, email: '' }));}}>
                  <FontAwesomeIcon icon={faCopy} /> {/* Use FontAwesome icon */}
                </button>
                <button onClick={() => setEmailPopup({ show: false, email: '' })}>
                  <FontAwesomeIcon icon={faTimes} /> {/* Use FontAwesome icon */}
                </button>
              </div>
            )}
          </>
        );
      } else {
        return null;
      }
  };

  const renderEditButton = () => {
    if (onEdit) {
      return (
        <button
          className="edit-btn"
          onClick={() => onEdit(job.jobID)}
        >
          EDIT
        </button>
      );
    }
    return null;
  };

  const renderDeleteButton = () => {
    if (onDelete) {
      return (
        <button
          className="delete-btn" // Make sure you have appropriate CSS for this
          onClick={() => onDelete(job.jobID)}
        >
          DELETE
        </button>
      );
    }
    return null;
  };

  const renderStarBar = () => {
      return onStarClick !== null ? (
          job.star ? 
              <FontAwesomeIcon 
                  className="gold-star-icon" 
                  icon={faBookmarkSolid} 
                  color="#2FC01A"
                  onClick={() => onStarClick(job.jobID)}
              /> :
              <FontAwesomeIcon 
                  className="star-icon-grey" 
                  icon={faBookmarkRegular} 
                  color="#A4A4A4"
                  onClick={() => onStarClick(job.jobID)}
              />
      ) : null;
  };

  const renderSeeMoreButton = () => {
      if (!showSeeMoreButton || !job.jobID) return null;

      return (
        <button 
          onClick={handleSeeMoreClick} 
          className="apply-btn see-more-btn"
        >
          See More
        </button>
      );
    };

  const renderPay = () => {
    if (minPay === null && maxPay === null) return null;
    if (minPay === maxPay) return `${maxPay} ${currency} ${payInterval}`;
    if (minPay === null) return `up to ${maxPay} ${currency} ${payInterval}`;
    return `${minPay} - ${maxPay} ${currency} ${payInterval}`;
  };

  const createMarkupForDescription = (description) => {
    const lines = description.split('\n');
    const transformedLines = lines.map(line => {
      if (/^\s*-\s*/.test(line)) {
        return `<li>${line.replace(/^\s*-\s*/, '')}</li>`;
      } else {
        return line;
      }
    });

  // Join the lines back together, replacing sequences of <li> elements with a wrapped <ul>
  const sanitizedDescription = transformedLines.join('\n').replace(/(<li>.*?<\/li>\n?)+/g, '<ul>$&</ul>');

  // Replace remaining new lines with <br/> tags for lines not converted to list items
  const htmlDescription = sanitizedDescription.replace(/\n/g, '<br/>');

  return { __html: htmlDescription };
};


  const renderSkillsLegend = () => {
    if (!showSkillsLegend) return null; // Only render if showSkillsLegend is true
    return (
      <div className="skills-legend">
        <JobProperty category="primarySkill" value="Essential" />
        &
        <JobProperty category="secondarySkill" value="Preferred" />
        Skills:
      </div>
    );
  };

  return (
    <div className="job-card" onClick={(e) => handleCardClick && handleCardClick(e)}>
      <div className="job-content">
        <div className="header-container">
          {(onDelete || onEdit) && (
            <div className="buttons-container">
              {onDelete && renderDeleteButton()} {/* Delete button */}
              {onEdit && renderEditButton()} {/* Edit button */}
            </div>
          )}
          {(onStarClick) && (
            <div className="starbar-container" onClick={(e) => e.stopPropagation()}>
              {renderStarBar()}
            </div>
          )}
          <p className="title">{jobTitle}</p>
        </div>
        <div className="top-row">
          {company && <JobProperty category="company" value={company} moreInfo={job.briefSummary} />}
          {location && <JobProperty category="location" value={location} />}
          {showDate && <JobProperty category="date" value={formatDate(added)} />}
        </div>
        <div className="other-rows">
          <div className="other-rows1">
            {renderPay() && <JobProperty category="pay" value={renderPay()} />}
            {employmentType && <JobProperty category="employmentType" value={employmentType} />}
            {workArrangement && <JobProperty category="workArrangement" value={workArrangement} />}
            {seniority && <JobProperty category="seniority" value={seniority} />}
            {experience !== null && experience !== undefined && <JobProperty category="experience" value={experience} />}
          </div>
          {renderSkillsLegend()}
          {combinedSkills.length > 0 && combinedSkills.map(({ skill, type }, index) => (
              <JobProperty key={index} category={type} value={skill} />
          ))}
        </div>
        {showSummary && description_summary && (
          <div className="description-container">
            <p className="description-title">Summary</p>
            <p className="description" dangerouslySetInnerHTML={createMarkupForDescription(description_summary)}></p>
          </div>
        )}
        {(showLinks || showSeeMoreButton) && (
          <div className="link-buttons">
            {renderLinkButtons(link, job.email, job.whatsapp)}
            {renderSeeMoreButton()}
          </div>
        )}
      </div>
    </div>
  );
};

JobCard.propTypes = {
  job: PropTypes.shape({
    link: PropTypes.string,
    email: PropTypes.string,
    whatsapp: PropTypes.string,
    // The rest of your existing PropTypes
  }).isRequired,
  maxSkills: PropTypes.number,
  skillOrder: PropTypes.arrayOf(PropTypes.string),
  showSeeMoreButton: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default JobCard;
