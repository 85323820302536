import React, { useState, useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

function CookieConsentComponent() {
  const [showConsent, setShowConsent] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleAccept = () => {
    // Implement what happens when users accept (e.g., initialize Google Analytics)
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConsent(true);
    }, 1000); // Delay of 1 second

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define styles for desktop and mobile to make it easier to adjust
  const baseStyle = {
    background: "#606060",
    fontSize: "14px",
    borderRadius: "20px",
    position: "fixed",
    boxSizing: 'border-box',
    padding: '5px'
  };

  const desktopStyle = {
    ...baseStyle,
    width: "70%",
    left: "15%",
    marginBottom: "3%",
  };

  const mobileStyle = {
    ...baseStyle,
    width: "90%",
    left: "5%",
    marginBottom: "5%",
    paddingBottom: "5%",
    alignItems: 'center',
    justifyContent: 'center', // Adjust items vertically
  };


  return (
    <>
      {showConsent && (
        <>
          {isMobile ? (
            <div style={mobileStyle}>
              <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="myAwesomeCookieName"
                style={mobileStyle}
                buttonStyle={{ 
                  background: "white", 
                  color: "#606060", 
                  fontSize: "13px",
                  borderRadius: "5px",
                  margin: '0 0px', // Add margin to separate buttons
                }}
                declineButtonStyle={{ 
                  background: "white", 
                  color: "#606060", 
                  fontSize: "13px",
                  borderRadius: "5px",
                  margin: '0 10px', // Add margin to separate buttons
                }}
                expires={150}
                onAccept={handleAccept}
                enableDeclineButton
              >
                This website uses cookies to enhance the user experience and analyze site traffic. By clicking "Accept," you consent to our use of cookies for these purposes. For more information, please read our 
                <a href="/PrivacyPolicy" style={{ color: "#fff", textDecoration: "underline" }}> Privacy Policy</a>.
              </CookieConsent>
            </div>
          ) : (
            <div style={desktopStyle}>
              {/* Desktop-specific CookieConsent component */}
              <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="myAwesomeCookieName"
                style={desktopStyle}
                buttonStyle={{ 
                  background: "white", 
                  color: "#606060", 
                  fontSize: "13px",
                  borderRadius: "5px"
                }}
                declineButtonStyle={{ 
                  background: "white", 
                  color: "#606060", 
                  fontSize: "13px",
                  borderRadius: "5px"
                }}
                expires={150}
                onAccept={handleAccept}
                enableDeclineButton
              >
                This website uses cookies to enhance the user experience and analyze site traffic. By clicking "Accept," you consent to our use of cookies for these purposes. For more information, please read our 
                <a href="/PrivacyPolicy" style={{ color: "#fff", textDecoration: "underline" }}> Privacy Policy</a>.
              </CookieConsent>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CookieConsentComponent;
