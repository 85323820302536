import React from 'react';
import './pieChart.css'; // Make sure the path to your CSS file is correct

const NoDataMessage = ({ title }) => {

  return (
    <div className="chart-container">
        <div className="responsive-container-wrapper">
          <div className="no-data-message">
            Not enough data.<br />Try adjusting the filters!
          </div>
        </div>
    </div>
  );

};

export default NoDataMessage;
