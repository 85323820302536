import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, Button, IconButton, Drawer,
  Dialog, DialogContent, useMediaQuery, useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import CurrencySelector from './currencySelector'; 
import { SignInButton, SignOutButton, useUser, UserButton, useClerk } from '@clerk/clerk-react';
import './TopBar_mui.css'

function TopBar({ setCurrency }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const currentPath = location.pathname;
  const { openSignIn } = useClerk();
  const { isSignedIn, user } = useUser();

  const renderAuthAndCurrency = () => (
    <div className="authAndCurrency">
      <RouterLink to="/employers/jobs" className="common-button-style job-button">Add Job</RouterLink>
      <CurrencySelector setCurrency={setCurrency} className="currencySelector" />
      {user ? (
        <div className="user-button-container">
          <UserButton afterSignOutUrl="/" />
        </div>
      ) : (
        <div className="login-button-container">
          <button onClick={() => openSignIn()} className="common-button-style">
            Login
          </button>
        </div>
      )}
    </div>
  );


  const renderDesktopMenuItems = () => (
    <div className="LeftMenu">
      <RouterLink to="/" className={`common-button-style ${currentPath === '/' ? 'active-link' : ''}`}>Job Map</RouterLink>
      <RouterLink to="/analysis" className={`common-button-style ${currentPath === '/analysis' ? 'active-link' : ''}`}>Market Analysis</RouterLink>
      <RouterLink to="/feed" className={`common-button-style ${currentPath === '/feed' ? 'active-link' : ''}`}>Trends</RouterLink>
      {//<RouterLink to="/resources" className={`common-button-style ${currentPath === '/resources' ? 'active-link' : ''}`}>Resources</RouterLink>
      //<RouterLink to="/about" className={`common-button-style ${currentPath === '/about' ? 'active-link' : ''}`}>About</RouterLink>
      }
    </div>
  );

  const renderMobileMenuItems = () => (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center" // Align items
      sx={{ width: 250, padding: 2 }}
    >
      <RouterLink 
        to="/" 
        className={`common-button-style ${currentPath === '/' ? 'active-link' : ''}`}
        onClick={() => setDrawerOpen(false)} // Close drawer on click
      >Job Search</RouterLink>

      <RouterLink 
        to="/analysis" 
        className={`common-button-style ${currentPath === '/analysis' ? 'active-link' : ''}`}
        onClick={() => setDrawerOpen(false)} // Close drawer on click
      >Market Analysis</RouterLink>

      <RouterLink 
        to="/feed" 
        className={`common-button-style ${currentPath === '/feed' ? 'active-link' : ''}`}
        onClick={() => setDrawerOpen(false)} // Close drawer on click
      >Trends</RouterLink>

      <RouterLink 
        to="/employers/jobs" 
        className="common-button-style"
        onClick={() => setDrawerOpen(false)} // Close drawer on click
      >Add Job</RouterLink>

      <div className="mobile-menu-item">
        <CurrencySelector setCurrency={setCurrency} />
      </div>

      {user ? (
        <UserButton afterSignOutUrl="/" />
      ) : (
        <button onClick={() => openSignIn()} className="common-button-style">
          Login
        </button>
      )}

    </Stack>
  );


return (
  <AppBar position="static" className="AppBar">
    <Toolbar className="Toolbar">
      {isMobile ? (
        <>
          <h1 style={{flexGrow: 1, textAlign: 'center'}}>Kdb+Jobs</h1>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
            style={{position: 'absolute', right: 0, marginRight: '3%'}}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
            <div className="menuItemsContainer">
              {renderMobileMenuItems()}
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <div className="LeftAlignedItems">
            <h1>Kdb+Jobs</h1>
            {renderDesktopMenuItems()}
          </div>
          <div className="RightMenu">
            {renderAuthAndCurrency()}
          </div>
        </>
      )}
    </Toolbar>
  </AppBar>
);


}

export default TopBar;
