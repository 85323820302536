import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchJobDetail, fetchFavouriteJobs, setFavouriteJob } from '../api';
import JobCard from '../jobCard';
import { useUser, useClerk } from '@clerk/clerk-react';
import './JobPage.css'

const JobPage = () => {
  let { jobID } = useParams();

  const { isSignedIn, user } = useUser();
  const { openSignIn } = useClerk();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(''); // Add state to handle error
  const [starred, setStarred] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobDetail = await fetchJobDetail(jobID);
        if (jobDetail.error) { // Check if the response contains an error
          setError(jobDetail.error); // Set the error state
        } else {
          setJob(jobDetail);
          setError(''); // Reset error state if there's a valid job
        }
      } catch (error) {
        console.error("Failed to fetch job detail:", error);
        setError('Failed to fetch job detail'); // Set error state for fetch errors
      }
    };

    fetchJob();
  }, [jobID]); // This will re-fetch if jobID changes


  useEffect(() => {
    if (user) {
      const fetchFavJobs = async () => {
        try {
          const data = await fetchFavouriteJobs(user.id); // Fetch the favourite jobs using the user's ID
          if (data && data.favourite_jobs) {
            // Correctly update 'starred' state to reflect whether jobID is in the favourite jobs list
            setStarred(data.favourite_jobs.includes(job?.jobID));
          }
        } catch (error) {
          console.error("An error occurred while fetching favourite jobs: ", error);
        }
      };

      fetchFavJobs();
    }
  }, [user, job?.jobID]);


  useEffect(() => {
    // Only update if 'job' exists
    if (job) {
      // Create a new object with all existing job fields, but overwrite 'star' with the value of 'starred'
      const updatedJob = { ...job, star: starred };
      // Update the job state with the new object
      setJob(updatedJob);
    }
  }, [starred]);


  if (error) {
    return (
    <div className="jobPageContainer">
      <h2>Job Not Found</h2>
      <p>Job may be inactive or removed</p>
      <p>Newly activated jobs may take 10 minutes to be registered</p>
    </div>
    )
  }

  const toggleFavouriteJobForUser = async (jobId) => {

    if (!isSignedIn || !user || !user.id) {
      openSignIn();
      return; // Stop the function execution here
    }

    try {
      const should_add = !starred; // If currently true (favourited), should_add will be false (remove)

      await setFavouriteJob(user.id, jobId, should_add);

      setStarred(should_add);
    } catch (error) {
      console.error("An error occurred while updating the favorite job status for user: ", error);
      throw error;
    }
  };


  return job ? (
    <div className="jobPageContainer">
      <JobCard job={job} showDate={true} showLinks={true} showSummary= {true} showSkillsLegend={true} onStarClick = {toggleFavouriteJobForUser}/>
    </div>
  ) : (
    <div className="loadingContainer">Loading...</div>
  );
};

export default JobPage;
