import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const FilterDropdown = ({ label, options, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (selectedValue) {
      onChange(selectedValue);
      setSelectedValue(null);
    }
  }, [selectedValue, onChange]);

  const handleChange = value => {
    setSelectedValue(value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '15px',
      fontSize: '0.8em',
      paddingLeft: '1.2em',
      borderStyle: 'none',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.8em', // Set the font size for the dropdown items
      padding: 5, // Adjust the padding for the dropdown items
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'auto', // This will allow the menu to size based on content
      minWidth: '150%', // This will ensure the menu is at least as wide as the control
      marginLeft: '-18.75px',
      zIndex: '5',
    }),
    menuList: (provided, state) => ({
      ...provided,
      // If you want to control the max height of the menu or other styles
    }),
    // Include other custom styles if needed
  };

  // Custom Control component to include the search icon with a gap
  const ControlComponent = props => (
    <components.Control {...props}>
      <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 2 }} />
      {props.children}
    </components.Control>
  );

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={handleChange}
      className="react-select-container"
      classNamePrefix="react-select"
      isClearable={true}
      isSearchable={true}
      placeholder={label}
      components={{ Control: ControlComponent, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={customStyles}
    />
  );
};

export default FilterDropdown;
