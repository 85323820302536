import React from 'react';
import './customTooltip.css';

const CustomTooltip = ({ active, payload, label, valueFormatter }) => {
  if (active && payload && payload.length) {
    const { value, name } = payload[0];
    return (
      <div className="custom-tooltip">
        <p>{label}</p>
        {/* Pass both value and name to the valueFormatter */}
        <p>{valueFormatter ? valueFormatter(value, name) : value}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
