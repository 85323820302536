import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy for kdbplusjobs.com</h1>

            <h2>1. Introduction</h2>
            <p>Welcome to kdbplusjobs.com. Our privacy policy explains the types of information we collect through our job site, which is primarily aimed at job seekers viewing job postings, and how we use this information.</p>

            <h2>2. Data Collection</h2>
            <p>We do not collect personal data directly on our website. However, we use Google Analytics to understand website traffic, such as the number of visitors and their geographical locations. If you choose to sign up, this is managed entirely through Google Authentication, and we do not handle your email addresses or any other sign-up information.</p>

            <h2>3. Data Usage</h2>
            <p>The data collected by Google Analytics is used solely to analyze website traffic and improve our services. We do not use this data for any other purpose.</p>

            <h2>4. Data Sharing</h2>
            <p>We do not share any data with third parties.</p>

            <h2>5. Data Storage</h2>
            <p>As we do not collect personal data, we do not store any on our site. Google Analytics may store data, but this is governed by Google's privacy policy.</p>

            <h2>6. User Rights</h2>
            <p>Since we do not collect or store personal data, traditional user rights applicable to personal data are not relevant in our case. However, for concerns regarding the data collected by Google Analytics, users may refer to Google's privacy policy.</p>

            <h2>7. Cookies and Tracking</h2>
            <p>Our website uses Google Analytics, which employs cookies and similar tracking technologies to gather data about our website traffic. This data is not personally identifiable.</p>

            <h2>8. Policy Changes</h2>
            <p>Any changes to our privacy policy will be updated on our website's privacy policy page.</p>

            <h2>9. Contact Information</h2>
            <p>For any privacy-related inquiries, please contact us at will@leftjoindata.com.</p>
        </div>
    );
}

export default PrivacyPolicy;
