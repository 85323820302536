import React, { useState, useEffect } from 'react';
import { fetchEmployerByCompany, updateEmployerDetails } from '../api'; // Ensure correct import path
import './EmployersPortal.css';

function CompanyForm({ company = '' }) { // Correctly destructure `company` with a default value
  const [companyDetails, setCompanyDetails] = useState({
    company,
    industry: '',
    headquartersCity: '',
    headquartersCountry: '',
    briefSummary: '',
    summary: ''
  });

  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    const loadCompanyDetails = async () => {
      // Early exit if company is null or empty
      if (!company) {
        console.log('No company provided');
        return;
      }
      
      try {
        console.log(`company ${company}`);
        const data = await fetchEmployerByCompany(company);
        // Check if data is null or empty array
        if (!data || data.length === 0) {
          throw new Error('No company details found');
        } else {
          const companyInfo = data[0]; // Extract the first item from the array
          setCompanyDetails({
            company: company,
            industry: companyInfo.industry || '',
            headquartersCity: companyInfo.headquartersCity || '',
            headquartersCountry: companyInfo.headquartersCountry || '',
            briefSummary: companyInfo.briefSummary || '',
            summary: companyInfo.summary || ''
          });
        }
      } catch (error) {
        // This catch block is for errors during fetching company details or if no data is found
        console.error('Failed to load company details:', error.message);
        alert('Failed to load company details');
      }
    };

    loadCompanyDetails();
  }, [company]); // Dependency array to re-run the effect if `company` changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
    setIsFormChanged(true); // Set form changed state to true
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateEmployerDetails({ companyDetails });
      // Check if the response contains an 'error' key
      if (response.error) {
        // Handle the case where the API indicates failure
        console.error('Failed to update company details:', response.error);
        alert(`Failed to update company details: ${response.error}`);
      } else {
        // Handle the case where the API indicates success
        console.log('Update successful:', response);
        setIsFormChanged(false);
        alert('Company details updated successfully!');
      }
    } catch (error) {
      // This catch block is for network errors or other issues preventing the request
      console.error('Failed to update company details:', error);
      alert('Failed to update company details. Please check your network connection and try again.');
    }
  };

  return (
    <div className="employers-portal">
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label className="form-label">Industry: </label>
          <input
            type="text"
            name="industry"
            value={companyDetails.industry}
            onChange={handleChange}
            className="form-input"
            maxLength="100"
            readOnly
            style={{
              backgroundColor: '#F5F5F5',
            }}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Headquarters City: </label>
          <input
            type="text"
            name="headquartersCity"
            value={companyDetails.headquartersCity}
            onChange={handleChange}
            className="form-input"
            maxLength="100"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Headquarters Country: </label>
          <input
            type="text"
            name="headquartersCountry"
            value={companyDetails.headquartersCountry}
            onChange={handleChange}
            className="form-input"
            maxLength="100"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Company Summary: </label>
          <input
            type="text"
            name="briefSummary"
            value={companyDetails.briefSummary}
            onChange={handleChange}
            className="form-input"
            maxLength="35"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Company Description: </label>
          <textarea
            name="summary"
            value={companyDetails.summary}
            onChange={handleChange}
            className="form-textarea"
            maxLength="500"
          />
        </div>
        <button type="submit" className={`form-button ${isFormChanged ? 'active' : 'inactive'}`} disabled={!isFormChanged}>Save</button>
      </form>
    </div>
  );
}

export default CompanyForm;
