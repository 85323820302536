import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, IconButton, Drawer,
  useMediaQuery, useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import './EmployersTopBar.css'

function EmployersTopBar({company = ''}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const currentPath = location.pathname;

  const renderDesktopMenuItems = () => (
    <div className="LeftMenu">
      <Link to="/employers/jobs" className={`common-button-style ${currentPath === '/employers/jobs' ? 'active-link' : ''}`}>Jobs</Link>
      <Link to="/employers/details" className={`common-button-style ${currentPath === '/employers/details' ? 'active-link' : ''}`}>Company Details</Link>
    </div>
  );

  return (
    <AppBar position="static" className="jobPortal-AppBar">
      <Toolbar className="jobPortal-Toolbar">
        <div className="jobPortal-LeftAlignedItems">
          <Typography variant="h6" className="jobPortal-Title">{company}</Typography>
          {renderDesktopMenuItems()}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default EmployersTopBar;
