import { fetchExchangeRates } from './api'; // Assuming api.js is in the same directory

let currentExchangeRates = null;

// Function to convert currency
export const convertCurrency = (amount, fromCurrency, toCurrency) => {
  if (isNaN(amount) || amount === null || typeof amount === "string") {
    return amount;
  }

  if (!currentExchangeRates) {
    console.error("Exchange rates not available.");
    return null;
  }

  // Converting from a currency to itself just returns the same amount
  if (fromCurrency === toCurrency) return amount;

  // Convert amount to USD as a common base, then to the target currency
  const amountInUSD = amount / currentExchangeRates[fromCurrency];
  const convertedAmount = amountInUSD * currentExchangeRates[toCurrency];

  return convertedAmount;
};

// Function to update exchange rates
export const updateExchangeRates = async () => {
  try {
    const data = await fetchExchangeRates();
    if (data && data.rates) {
      currentExchangeRates = data.rates;
    }
  } catch (error) {
    console.error('Failed to update exchange rates:', error);
  }
};

// Initialize by fetching exchange rates
updateExchangeRates();

// Update exchange rates every hour
setInterval(updateExchangeRates, 1000 * 60 * 60);
