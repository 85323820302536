import React from 'react';
import './MailchimpForm.css'; // Assumed the CSS from your HTML is placed in this file

class MailchimpForm extends React.Component {
  componentDidMount() {
    // Ensure your Mailchimp script is compatible with React's lifecycle
    // You might need to adjust this to work more effectively with React
    const script = document.createElement('script');
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.async = true;
    document.body.appendChild(script);
    
    script.onload = () => {
      // Initialize your Mailchimp form here, if needed
      // This might involve adapting the original Mailchimp JS to be more 'React-friendly'
    };
  }

  render() {
    return (
      <div id="mc_embed_signup">
        <form action="https://kdbplusjobs.us18.list-manage.com/subscribe/post?u=f3b2c08ff3ee2062cb0cf51cc&amp;id=e5daf25add&amp;f_id=007023e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
          <div id="mc_embed_signup_scroll">
            <div className="mailchimp-intro">Subscribe to the Newsletter</div>
            <div className="mailchimp-intro1">Insightful content and updates to your inbox</div>
            <div className="mc-field-group">
              <input 
                type="text" 
                name="EMAIL" 
                className="email" 
                id="mce-EMAIL" 
                placeholder="Email Address" // Add this line
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            </div>
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              {/* Real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <input type="text" name="b_f3b2c08ff3ee2062cb0cf51cc_e5daf25add" tabIndex="-1" />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default MailchimpForm;
