import React, { useEffect, useState } from 'react';
import './noJobSelected.css';

const NoJobSelected = ({ jobs }) => {
  const [jobsCount, setJobsCount] = useState(0);
  const [displayedJobsCount, setDisplayedJobsCount] = useState(0); // State to handle the animated count

  useEffect(() => {
    setJobsCount(jobs.length);

    let interval;

    // Animate the displayedJobsCount
    if (jobs.length !== displayedJobsCount) {
      interval = setInterval(() => {
        setDisplayedJobsCount(prevCount => {
          if (prevCount < jobs.length) {
            return prevCount + 1; // Adjust this increment as needed
          } else {
            clearInterval(interval);
            return jobs.length;
          }
        });
      }, 1); // Adjust timing as needed
    }

    // Clear interval on cleanup
    return () => interval && clearInterval(interval);
  }, [jobs, displayedJobsCount]);

  return (
    <div className="selection-prompt-container">
      <div className="selection-prompt-header-number">
        {displayedJobsCount}
      </div>
      <div className="selection-prompt-header">
        live Kdb+ job posts!
      </div>
      <div className="selection-prompt-message">
        Make a selection below
      </div>
    </div>
  );
}

export default NoJobSelected;
