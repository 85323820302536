import { useState, useEffect } from 'react';
import { fetchFavouriteJobs, setFavouriteJob } from '../api'; // Adjust the path as needed
import { useUser, useClerk } from '@clerk/clerk-react';

function useFavouriteJobs () {
  const [favouriteJobsList, setFavouriteJobsList] = useState([]);
  const { isSignedIn, user } = useUser();
  const { openSignIn } = useClerk();

  useEffect(() => {
    if (user) {
      const fetchFavJobs = async () => {
        try {
          const data = await fetchFavouriteJobs(user.id);
          if (data && data.favourite_jobs) {
            setFavouriteJobsList(data.favourite_jobs);
          }
        } catch (error) {
          console.error("An error occurred while fetching favourite jobs: ", error);
        }
      };

      fetchFavJobs();
    }
  }, [user]);

  const toggleFavouriteJobForUser = async (jobId) => {
    if (!user || !user.id) {
      openSignIn();
      return; // Optionally handle user not signed in
    }

    try {
      const should_add = !favouriteJobsList.includes(jobId);
      await setFavouriteJob(user.id, jobId, should_add);
      if (should_add) {
        setFavouriteJobsList(prevList => [...prevList, jobId]);
      } else {
        setFavouriteJobsList(prevList => prevList.filter(id => id !== jobId));
      }
    } catch (error) {
      console.error("An error occurred while updating the favorite job status for user: ", error);
      throw error;
    }
  };

  return { favouriteJobsList, toggleFavouriteJobForUser };
}

export default useFavouriteJobs;
