import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Tooltip as RechartsTooltip} from 'recharts';
import './pieChart.css';
import CustomTooltip from './customTooltip.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const truncateLabel = (label, maxLength) => {
  if (label.length > maxLength) {
    return `${label.substring(0, maxLength)}...`;
  }
  return label;
};

const GenericBarChartHorizontal = ({ 
    data,
    dataKey,
    dataValue,
    title,
    maxRows = 12,
    nameFormatter, 
    valueFormatter,
    yAxisAngle = 0,
    xAxisFontSize = '16px',
    yAxisFontSize = '16px',
    maxLabelLength = 8, // Adjust this value as needed
    infoDescription
}) => {
  const filteredAndSortedData = data
    .filter(item => item[dataKey] && item[dataKey].toLowerCase() !== 'null')
    .sort((a, b) => b[dataValue] - a[dataValue])
    .slice(0, maxRows);

  const colors = ['#5555FF','#7171FF'];

  return (
    <div className="chart-container">
      <div className="chart-title">
        {title}
        {infoDescription && (
            <span className="info-icon" data-tip={infoDescription}>
                    <FontAwesomeIcon icon={faCircleQuestion} color="#606060" />
                <span className="info-tooltip">{infoDescription}</span>
            </span>
        )}
      </div>

      <div className="responsive-container-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={filteredAndSortedData}
          >
            <XAxis 
              type="number" 
              height={50} 
              tick={{ fontSize: xAxisFontSize }}
              tickFormatter={(value) => truncateLabel(value, maxLabelLength)}
            />
            <YAxis
              dataKey={dataKey}
              type="category"
              interval={0}
              angle={yAxisAngle}
              {...(yAxisAngle === 0 ? {} : { textAnchor: "end" })}
              width={50}
              tick={{ fontSize: yAxisFontSize }}
              tickFormatter={(value) => truncateLabel(value, maxLabelLength)}
            />
            <RechartsTooltip content={<CustomTooltip valueFormatter={valueFormatter} />} />
            <Bar dataKey={dataValue}>
              {
                filteredAndSortedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GenericBarChartHorizontal;
