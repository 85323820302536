import React from 'react';
import '../about/About.css';

const ResourceList = () => {

    return (
        <div className="about-page" >

            <div className="intro-container">
                <div className="intro">Useful Resources for KDB+ Professionals and Learners</div>
            </div>

            <div className="faq-section">
                <div className="content-wrapper">
                    <div className="section-title">Learning</div>
                    <div className="faq-content">
                        <div className="faq-question-container">
                            <div className="faq-question">
                                <a href="https://kx.com/academy/" target="_blank" rel="noopener noreferrer">Kx Academy</a>
                            </div>
                            <div className="faq-answer">
                                The KX Academy presents an extensive collection of kdb+ learning resources, from introductory workshops to advanced subjects such as data on disk, query optimization, and parallelization. It also explores Python and SQL interfaces, alongside an introduction to KX products like kdb.ai, insights, and dashboards. Tutorials include access to a kdb+ Jupyter notebook, enabling learners to actively engage with and apply their newfound knowledge in a hands-on manner.
                            </div>
                        </div>
                        <div className="faq-question-container">
                            <div className="faq-question">
                                <a href="https://code.kx.com/q/ref/" target="_blank" rel="noopener noreferrer">Kdb+ Reference Card</a>
                            </div>
                            <div className="faq-answer">
                                The kdb+ reference card offers a concise overview of essential kdb+ components, including keywords, operators, iterators, execution control mechanisms, attributes, data types, system commands, and default namespaces, designed for quick and easy access.
                            </div>
                        </div>
                        <div className="faq-question-container">
                            <div className="faq-question">
                                <a href="https://www.timestored.com/kdb-guides/" target="_blank" rel="noopener noreferrer">TimeStored Kdb+ Tutorials</a>
                            </div>
                            <div className="faq-answer">
                                Timestored offer a comprehensive suite of learning materials for both beginners and advanced users, including video tutorials, guides on setting up development environments, kdb+ basics and specialized topics such as memory management, parallel processing, and utilizing kdb+ in trading systems. Additionally, it provides essential references like keyword lookups, cheat sheets, and API documentation to support developers in mastering kdb+.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faq-section">
                <div className="content-wrapper">
                    <div className="section-title">Blogs</div>
                    <div className="faq-content">
                        <div className="faq-question-container">
                            <div className="faq-question">
                                <a href="https://dataintellect.com/thoughts/?_sft_category=blog" target="_blank" rel="noopener noreferrer">Data Intellect</a>
                            </div>
                            <div className="faq-answer">
                                The Data Intellect blog blends discussions on KDB and Timeseries data with broader software engineering topics including AI, web and crypto. It showcases a breadth of technological expertise, catering to those keen on exploring cutting-edge data solutions and the broader landscape of software development with a focus on innovation.
                            </div>
                        </div>
                        <div className="faq-question-container">
                            <div className="faq-question">
                                <a href="https://kx.com/resource_type/blog/" target="_blank" rel="noopener noreferrer">KX Blog</a>
                            </div>
                            <div className="faq-answer">
                                The KX blog primarily focuses on showcasing KDB's capabilities through use cases and case studies, highlighting its integration within finance and other industries. It effectively demonstrates KDB's interaction with other technologies and platforms, providing a deep dive into its current and future role in data analytics.
                            </div>
                        </div>
                        <div className="faq-question-container">
                            <div className="faq-question">
                                <a href="https://www.defconq.tech/blog" target="_blank" rel="noopener noreferrer">DefconQ</a>
                            </div>
                            <div className="faq-answer">
                                The DefconQ blog, authored by KDB+ developer Alexander Unterrainer, offers a range of posts including some aimed at beginners trying to get started in the kdb+/Q programming domain. It covers topics from finding help to understanding and learning KDB/Q code and more.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="details-section">
                <div className="content-wrapper">
                    <div className="content-text">
                      <p>Reach out if you have something useful to add here!</p>
                    </div>
                </div>
            </div>


        </div>

    );
};

export default ResourceList;
