import React, { useState, useEffect } from 'react';
import { Route, Link, Routes } from 'react-router-dom';
import CompanyDetailsPage from './CompanyDetailsPage';
import CompanyJobsPage from './CompanyJobsPage';
import EmployersTopBar from './EmployersTopBar';
import AccessDenied from './AccessDenied';
import { useUser } from '@clerk/clerk-react';
import { fetchCompanyByUser, fetchUniqueColumnValues } from '../api';
import './EmployersPortal.css';

function EmployersPortal() {
  const [company, setCompany] = useState(null);
  const [isPrivileged, setIsPrivileged] = useState(false);
  const [uniqueColumnValues, setUniqueColumnValues] = useState({});
  const { isSignedIn, user } = useUser();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Check for mobile device based on user agent string
      if (/android|avantgo|bada\/|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(userAgent)) {
        setIsMobile(true);
      }
    };

    const fetchCompanyDetails = async () => {
      if (isSignedIn) {
        const primaryEmail = user.emailAddresses[0].emailAddress;
        const result = await fetchCompanyByUser(primaryEmail);
        if (result && result.company) {
          setCompany(result.company);
          setIsPrivileged(result.privileged === 1);
          console.log(result.privileged === 1);
        } else {
          console.error('No company found or error fetching company');
          setCompany(null);
        }
      }
    };

    const fetchColumns = async () => {
      const columns = ['location', 'country', 'industry', 'institutionType', 'seniority', 'employmentType', 'payInterval', 'currency', 'workArrangement', 'skills'];
      const result = await fetchUniqueColumnValues(columns);
      if (result) {
        setUniqueColumnValues(result);
      } else {
        console.error('Error fetching unique column values');
      }
    };

    checkIfMobile();
    fetchCompanyDetails();
    fetchColumns();
  }, [user, isSignedIn]);

  const isValidUserAndCompany = isSignedIn && company && company !== 'n/a' && company !== '';

  // Check for mobile device or invalid user/company before rendering main content
  if (isMobile || !isValidUserAndCompany) {
    return <AccessDenied isSignedIn={isSignedIn} />;
  }

  return (
    <div className="employers-portal">
      <EmployersTopBar company={company} />
      <Routes>
        <Route path="details" element={<CompanyDetailsPage company={company} isSignedIn={isSignedIn}/>} />
        <Route path="jobs" element={<CompanyJobsPage company={company} uniqueColumnValues={uniqueColumnValues} isSignedIn={isSignedIn} user={user} isPrivileged={isPrivileged}/>} />
      </Routes>
    </div>
  );
}

export default EmployersPortal;
