import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import CustomTooltip from './customTooltip.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const GenericLineChart = ({
      data,
      dataKey,
      lines, // Array of objects, each representing a line
      title,
      nameFormatter,
      valueFormatter,
      xAxisFontSize = '16px',
      yAxisFontSize = '16px',
      infoDescription
  }) => {

  // Check if dataKey is a date
  const isDateData = (data && data.length > 0 && !isNaN(Date.parse(data[0][dataKey])));

  // Function to format date ticks on XAxis
  const formatDate = (tickItem) => {
    return new Date(tickItem).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
      <div className="chart-container">
          <div className="chart-title">
            {title}
            {infoDescription && (
                <span className="info-icon" data-tip={infoDescription}>
                        <FontAwesomeIcon icon={faCircleQuestion} color="#606060" />
                    <span className="info-tooltip">{infoDescription}</span>
                </span>
            )}
          </div>
          <div className="responsive-container-wrapper">
              <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                          dataKey={dataKey}
                          tickFormatter={isDateData ? formatDate : undefined}
                          tick={{ fontSize: xAxisFontSize }}
                          type={isDateData ? "category" : "number"}
                      />
                      <YAxis
                          tick={{ fontSize: yAxisFontSize }}
                      />
                      <RechartsTooltip content={<CustomTooltip valueFormatter={valueFormatter} />} />
                      {lines.map((line, index) => (
                          <Line
                              key={index}
                              type="monotone"
                              dataKey={line.dataValue}
                              stroke={line.color}
                              connectNulls={true}
                          />
                      ))}
                  </LineChart>
              </ResponsiveContainer>
          </div>
      </div>
  );
};

export default GenericLineChart;
