import React, { useState, useEffect } from 'react';
import { fetchEmployerByCompany, updateEmployerDetails } from '../api'; // Ensure correct import path
import CompanyForm from './CompanyForm';
import AccessDenied from './AccessDenied';
import CompanyDetailsIntro from './CompanyDetailsIntro';
import './EmployersPortal.css'

function CompanyDetailsPage({ company = '', isSignedIn }) {

  const isValidUserAndCompany = isSignedIn && company && company !== 'n/a' && company !== '';
  if (!isValidUserAndCompany) {
    return <AccessDenied isSignedIn={isSignedIn}/>;
  }

  return (
    <div>
      <CompanyDetailsIntro company={company}/>
    	<CompanyForm company={company}/>
    </div>
  );

}

export default CompanyDetailsPage;