import React, { useState } from 'react';
import './Switch.css'; // Make sure to create a corresponding CSS file

const Switch = ({ onSwitchChange }) => {
  const [isRelevant, setIsRelevant] = useState(false);

  const handleSwitch = () => {
    const newState = !isRelevant;
    setIsRelevant(newState);
    onSwitchChange(newState ? 'company' : 'added');
  };

  return (
    <div className="switch-container" onClick={handleSwitch}>
      {/* Update the switch-button to include the text dynamically */}
      <div className={`switch-button ${isRelevant ? 'left' : 'right'}`}>
        {isRelevant ? 'Relevant' : 'New'} {/* Dynamically setting the text */}
      </div>
      <div className="switch-label left">Relevant</div>
      <div className="switch-label right">New</div>
    </div>
  );
};

export default Switch;
