import React, { useState, useEffect } from 'react';
import JobCard from '../jobCard';
import JobProperty from '../jobProperty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import './JobCardWrapper.css';

function JobCardWrapper({ job, jobStatus, hasChanges, onEditJob, directListing, onJobStatusChange, onClearChanges, onDelete }) {

  //this means changes were made if == 'status'
  useEffect(() => {
    //console.log(`hasChanges: ${hasChanges}`);
    //setResetCategory(hasChanges ? 'status' : 'status_grey');
  }, [hasChanges, job, jobStatus]);

  const currentDate = new Date(Date.UTC(new Date(jobStatus.expires).getUTCFullYear(), new Date(jobStatus.expires).getUTCMonth(), new Date(jobStatus.expires).getUTCDate()));
  const maxDate = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 60));

  const formatDate = (dateString) => {
    const parts = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (!parts) {
      return dateString;
    }
    const year = parts[1];
    const month = parts[2];
    const day = parts[3];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthShortName = monthNames[parseInt(month, 10) - 1];
    return `${monthShortName} ${parseInt(day, 10)}`;
  };

  const handleAddDays = () => {
    currentDate.setUTCDate(currentDate.getUTCDate() + 30); // Add 30 days to current expiry date
    if (currentDate > maxDate) {
      alert("Cannot set the job more than 60 days in advance."); // Show alert to the user
      return;
    }
    //setResetCategory('status');
    //setHasChanges(true);
    const newExpiresDate = currentDate.toISOString().split('T')[0];
    onJobStatusChange(job.jobID, { ...jobStatus, expires: newExpiresDate });
  };

  const handleSubtractDays = () => {
    const today = new Date();
    currentDate.setUTCDate(currentDate.getUTCDate() - 30);

    const newExpiresDate = new Date(Math.max(today, currentDate)).toISOString().split('T')[0];
    onJobStatusChange(job.jobID, { ...jobStatus, expires: newExpiresDate });
  };

  const deactivateJob = () => {
    const today = new Date(); // Sets currentDate to the current date in UTC
    today.setDate(today.getDate()); // Subtract one day from the current date
    const newExpiresDate = today.toISOString().split('T')[0]; // This is already in UTC
    onJobStatusChange(job.jobID, { ...jobStatus, expires: newExpiresDate });
  };

  const handleResetChanges = () => {
    //setResetCategory('status_grey');
    //setHasChanges(false);
    onClearChanges(job.jobID);
  };

  const getStatusText = () => {
    // Format the date to a readable format, assuming formatDate is defined
    // If not, replace 'formatDate(job['expires'])' with your date formatting function
    const expiryFormatted = job['expires'] ? formatDate(job['expires']) : 'No Expiry Date';

    // Get today's date in UTC
    const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD format

    // Check if the job is active and compare expiry dates
    if (job && job['active'] === 1) {
      // Check if the job has expired
      if (job['expires'] && job['expires'] < today) {
        return `Expires: TODAY (INACTIVE)`;
      } else {
        return `Expires: ${expiryFormatted}`;
      }
    } else {
      return `Expires: TODAY (INACTIVE)`;
    }
  };

  if (!job) {
    return (
      <div className="jobRow addJobContainer" onClick={() => onEditJob()}>
        <div className="addJobPlus">+</div>
        <div>Add Job</div>
      </div>
    );
  }

  const isExpiryDateWithinLimit = () => {
    const limitDate = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 60));
    return currentDate < limitDate;
  };

  return (
      <div className={"JobCardWrapper"}>
        <JobCard job={job} onEdit={onEditJob} onDelete={onDelete} showSeeMoreButton={jobStatus.active === 1 ? true : false}/>
        {directListing && job && (
          <>
            <div className="jobRow">

              <div className={jobStatus.active ? "jobRow-top-active" : "jobRow-top-inactive"}>
                <div className="statusText">{getStatusText()}</div>
                <button className="resetButton" onClick={handleResetChanges} title="Reset">
                  <FontAwesomeIcon icon={faRedo} />
                </button>
              </div>
              <div className="jobRow-bottom">
                <button onClick={handleSubtractDays} className={jobStatus.active ? "button-active" : "button-grey"}>- 30 Days</button>
                <button onClick={jobStatus.active ? deactivateJob : handleAddDays} 
                        className={jobStatus.active ? "deactivate-button" : "activate-button"}>
                    {jobStatus.active ? "Deactivate" : "Activate"}
                </button>
                <button onClick={handleAddDays} className={jobStatus.active && isExpiryDateWithinLimit() ? "button-active" : "button-grey"}>+ 30 Days</button>
              </div>

            </div>
          </>
        )}
      </div>
  );

}

export default JobCardWrapper;