import React, { useEffect, useState, useRef } from 'react';
import './jobTable.css';
import { convertCurrency, updateExchangeRates } from './currencyExchange.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faStar as faStarSolid, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular, faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';


updateExchangeRates();

const JobTable = ({ jobs, jobIDs, setJobIDs, currency, setTableSelection, onStarClick}) => {

  const headerToFieldMapping = {
    'Date': 'added',
    //'jobID': 'jobID',
    //'Link': 'link',
    //'email': 'email',
    //'whatsapp': 'whatsapp',
    'Job Title': 'jobTitle',
    'Company': 'company',
    'Source':'source',
    //'Site': 'site',
    'City': 'location',
    'Country': 'country',
    'Seniority': 'seniority',
    'Employment Type': 'employmentType',
    //'Min Pay': 'minPay',
    'Pays up to': 'maxSalary',
    //'Pay Period': 'payInterval',
    //'Currency': 'currency',
    'Work Arrangement': 'workArrangement',
    //'Years Exp': 'experience',
    'Primary Skills': 'primarySkills',
    'Secondary Skills': 'secondarySkills',
    //'': 'star'
  };

  const headers = Object.keys(headerToFieldMapping);

  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState("added");
  const [sortDirection, setSortDirection] = useState('desc');
  const [prevFilteredJobs, setPrevFilteredJobs] = useState([]);
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const prevDisplayedJobsRef = useRef([]);

  const handleFilterChange = (e, header) => {
    const newFilters = { ...filters };
    newFilters[headerToFieldMapping[header]] = e.target.value.toLowerCase();
    setFilters(newFilters);
  };

  // Convert maxSalary to the specified currency and a yearly amount
  const convertedJobs = jobs.map(job => {
    // Convert maxSalary
    let convertedMaxSalary = job.maxSalary;
    if (convertedMaxSalary != null) {
      convertedMaxSalary = convertCurrency(job.maxSalary, job.currency, currency);
      convertedMaxSalary = Math.round(convertedMaxSalary / 100) * 100;  // Round to the nearest 100
    }

    // Convert primarySkills and secondarySkills from JSON strings to comma-separated strings
    const primarySkills = job.primarySkills ? JSON.parse(job.primarySkills).join(", ") : "";
    const secondarySkills = job.secondarySkills ? JSON.parse(job.secondarySkills).join(", ") : "";

    return { 
      ...job, 
      maxSalary: convertedMaxSalary,
      primarySkills,
      secondarySkills
    };
  });

  const compareValues = (a, b, sortDirection) => {
    // Check for empty strings and sort them accordingly
    if (a === '' && b !== '') return sortDirection === 'asc' ? -1 : 1;
    if (b === '' && a !== '') return sortDirection === 'asc' ? 1 : -1;

    // Number comparison
    if (typeof a === 'number' && typeof b === 'number') {
      return sortDirection === 'asc' ? a - b : b - a;
    } else {
      // String comparison
      let comparison = a.toString().localeCompare(b.toString());
      return sortDirection === 'asc' ? comparison : -comparison;
    }
  };

  const sortedJobs = [...convertedJobs].sort((a, b) => {
    if (!sortField) return 0;

    const aValue = a[sortField] ?? '';
    const bValue = b[sortField] ?? '';

    return compareValues(aValue, bValue, sortDirection);
  });

  const filteredJobs = sortedJobs.filter(job => 
    Object.keys(filters).every(field => 
      String(job[field]).toLowerCase().includes(filters[field])
    )
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    const ids = filteredJobs.map(job => job.jobID);

    // Check if the filtered jobs have changed
    if (JSON.stringify(prevFilteredJobs) !== JSON.stringify(filteredJobs)) {
      setJobIDs(ids);
      setPrevFilteredJobs(filteredJobs);
    }
  }, [filteredJobs]);

  useEffect(() => {
    const newDisplayedJobs = filteredJobs.filter(job => jobIDs.includes(job.jobID));
    if (JSON.stringify(prevDisplayedJobsRef.current) !== JSON.stringify(newDisplayedJobs)) {
      setDisplayedJobs(newDisplayedJobs);
      prevDisplayedJobsRef.current = newDisplayedJobs;
    }
  }, [jobIDs, filteredJobs, sortField, sortDirection]);

  const [selectedJob, setSelectedJob] = useState(null);
  const handleRowClick = (job) => {
    setSelectedJob(job);
    if (setTableSelection) {
      setTableSelection(job.jobID);
    }
  };

  const handleKeyDown = (event) => {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      event.preventDefault(); // Prevent the default scrolling behavior

      const currentIndex = displayedJobs.findIndex(job => job === selectedJob);
      let newIndex = currentIndex;

      if (event.key === 'ArrowDown' && currentIndex < displayedJobs.length - 1) {
        newIndex = currentIndex + 1;
      } else if (event.key === 'ArrowUp' && currentIndex > 0) {
        newIndex = currentIndex - 1;
      }

      if (newIndex !== currentIndex) {
        const newSelectedJob = displayedJobs[newIndex];
        setSelectedJob(newSelectedJob);
        if (setTableSelection && newSelectedJob) {
          setTableSelection(newSelectedJob.jobID);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [displayedJobs, selectedJob, setTableSelection]);


  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} 
                  onClick={() => handleSort(headerToFieldMapping[header])} 
                  className={header === '' ? 'star-column' : ''}>
                {header}
                {sortField === headerToFieldMapping[header] && (
                  <FontAwesomeIcon 
                    icon={sortDirection === 'asc' ? faSortUp : faSortDown} 
                    style={{ marginLeft: '0.5rem' }}
                  />
                )}
              </th>
            ))}
          </tr>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className={header === '' ? 'star-column' : ''}>
                {header === '' ? (
                  <div style={{ textAlign: 'center' }}></div>
                ) : (
                  <input
                    type="text"
                    placeholder={`Filter`}
                    onChange={e => handleFilterChange(e, header)}
                    style={{ width: '100%' }}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
          <tbody>
            {displayedJobs.map((job, index) => (
              <tr key={index} onClick={() => handleRowClick(job)} 
                  className={`${selectedJob === job ? 'selected' : ''} ${job.star ? 'highlighted-row' : ''}`}>
                {headers.map((header, i) => (
                  <td key={i} className={header === '' ? 'star-column' : ''}>
                    {
                      headerToFieldMapping[header] === 'star'
                        ? job[headerToFieldMapping[header]]
                          ? <FontAwesomeIcon className="gold-star-icon-tbl" icon={faBookmarkSolid} color="#BBBBBB" onClick={(e) => { e.stopPropagation(); onStarClick(job.jobID); }} />
                          : <FontAwesomeIcon icon={faBookmarkRegular} color="#606060" onClick={(e) => { e.stopPropagation(); onStarClick(job.jobID); }} />
                        : job[headerToFieldMapping[header]] === null || job[headerToFieldMapping[header]] === undefined
                          ? ''
                          : typeof job[headerToFieldMapping[header]] === 'object'
                            ? JSON.stringify(job[headerToFieldMapping[header]])
                            : capitalizeFirstLetter(job[headerToFieldMapping[header]].toString())
                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
      </table>
    </div>
  );

};

export default JobTable;


const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
