import React, { useState, useEffect } from 'react'; // Make sure to import useState and useEffect
import { PieChart, Pie, Tooltip as RechartsTooltip, Cell, ResponsiveContainer } from 'recharts';
import './pieChart.css';
import CustomTooltip from './customTooltip.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const COLORS = ['#5555FF', '#AED5DF', '#7171FF','#EFF5FB','#C6DCE3'];

const GenericPieChart = ({
  data,
  dataKey,
  dataValue,
  title,
  nameFormatter,
  valueFormatter,
  infoDescription
}) => {
  // Hooks must be used inside the component
  const [labelDistance, setLabelDistance] = useState();
  const [labelFontSize, setLabelFontSize] = useState();
  const [outerRadius, setOuterRadius] = useState();
  const [innerRadius, setInnerRadius] = useState();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setLabelDistance(20);
        setLabelFontSize('0.8em');
        setOuterRadius(60);
        setInnerRadius(0);
      } else {
        setLabelDistance(20);
        setLabelFontSize('1em');
        setOuterRadius(100);
        setInnerRadius(85);
      }
    }

    // Call handleResize when the component mounts
    handleResize();

    // Setup event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Exclude 'null' values for the key and sort by value
  const filteredAndSortedData = data
    .filter(item => item[dataKey] && item[dataKey].toLowerCase() !== 'null')
    .sort((a, b) => b[dataValue] - a[dataValue]);

// Existing or new nameFormatter function that includes abbreviation logic
  const nameAbreviator = (name) => {
    const abbreviations = {
      "United Kingdom": "UK",
      "United States": "USA"
    };
    // Replace full country name with abbreviation if it exists in the abbreviations object
    return abbreviations[name] || name;
  };

  // Your renderCustomizedLabel function remains the same, as it already calls nameFormatter
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = outerRadius + labelDistance;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    const name = nameAbreviator(filteredAndSortedData[index][dataKey]);
    const trimmedName = name.length > 12 ? `${name.substring(0, 10)}...` : name;

    return (
      <text
        x={x}
        y={y}
        fill="#606060"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{ fontSize: labelFontSize }}
      >
        {trimmedName}
      </text>
    );
  };

  return (
    <div className="chart-container">
      <div className="chart-title">
        {title}
        {infoDescription && (
          <span className="info-icon" data-tip={infoDescription}>
            <FontAwesomeIcon icon={faCircleQuestion} color="#606060" />
            <span className="info-tooltip">{infoDescription}</span>
          </span>
        )}
      </div>
      <div className="responsive-container-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={filteredAndSortedData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={outerRadius} // You can adjust outerRadius as needed
              innerRadius={innerRadius} // Adjust this value to change the thickness of the donut
              fill="#8884d8"
              dataKey={dataValue}
              nameKey={dataKey}
            >
              {filteredAndSortedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <RechartsTooltip content={<CustomTooltip valueFormatter={valueFormatter} />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GenericPieChart;
