import React, { useState } from 'react';
import './currencySelector.css';

function CurrencySelector({ setCurrency }) {
  const [localCurrency, setLocalCurrency] = useState(getLocalCurrency());

  const handleChange = (event) => {
    const newCurrency = event.target.value;
    setLocalCurrency(newCurrency);
    setCurrency(newCurrency); // Update the parent state
  };

  return (
    <div className="currency-selector">
      <select value={localCurrency} onChange={handleChange}>
        <option value="AUD">AUD</option>
        <option value="CAD">CAD</option>
        <option value="CHF">CHF</option>
        <option value="CNY">CNY</option>
        <option value="EUR">EUR</option>
        <option value="GBP">GBP</option>
        <option value="HKD">HKD</option>
        <option value="INR">INR</option>
        <option value="JPY">JPY</option>
        <option value="NZD">NZD</option>
        <option value="SGD">SGD</option>
        <option value="USD">USD</option>
      </select>
    </div>
  );
}

export default CurrencySelector;

export const getLocalCurrency = () => {
  const locale = navigator.language;
  let currency = 'USD'; // Default to USD

  // Mapping of locale to currency (this is a simplified example)
  const localeCurrencyMap = {
    'en-AU': 'AUD',
    'en-CA': 'CAD',
    'de-CH': 'CHF',
    'zh-CN': 'CNY',
    'en-GB': 'GBP',
    'zh-HK': 'HKD',
    'en-IN': 'INR',
    'ja-JP': 'JPY',
    'en-NZ': 'NZD',
    'en-SG': 'SGD',
    // Add more mappings as needed
  };

  if (localeCurrencyMap[locale]) {
    currency = localeCurrencyMap[locale];
  }

  return currency;
};
