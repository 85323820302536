import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Cell } from 'recharts';
import NoDataMessage from './noDataMessage.jsx';
import './pieChart.css';
import CustomTooltip from './customTooltip.jsx';
import './customTooltip.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
        return `${label.substring(0, maxLength)}...`;
    }
    return label;
};

const GenericBarChart = ({
    data,
    dataKey,
    dataValue,
    title,
    nameFormatter,
    valueFormatter,
    sortOrder = 'desc',
    xAxisAngle = 0,
    xAxisFontSize = '16px',
    yAxisFontSize = '16px',
    maxLabelLength = 10,
    infoDescription,
    maxBars = data.length
}) => {
    const filteredAndSortedData = data
        .filter(item => item[dataKey] && item[dataKey].toLowerCase() !== 'null')
        .sort((a, b) => sortOrder === 'asc' ? a[dataValue] - b[dataValue] : b[dataValue] - a[dataValue])
        .slice(0, maxBars); // Limit the number of bars based on maxBars

    const colors = ['#5555FF', '#7171FF'];
    const enoughData = filteredAndSortedData.length > 1;

    return (
        <div className="chart-container">
            <div className="chart-title">
                {title}
                {infoDescription && (
                    <span className="info-icon" data-tip={infoDescription}>
                        <FontAwesomeIcon icon={faCircleQuestion} color="#606060" />
                        <span className="info-tooltip">{infoDescription}</span>
                    </span>
                )}
            </div>
            {enoughData ? (
                <div className="responsive-container-wrapper">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={filteredAndSortedData}
                            label={nameFormatter}
                        >
                            <XAxis
                                dataKey={dataKey}
                                interval={0}
                                angle={xAxisAngle}
                                {...(xAxisAngle === 0 ? {} : { textAnchor: 'end' })}
                                height={50}
                                tick={{ fontSize: xAxisFontSize }}
                                tickFormatter={(value) => truncateLabel(value, maxLabelLength)}
                            />
                            <YAxis
                                width={50}
                                tick={{ fontSize: yAxisFontSize }}
                                tickFormatter={(value) => truncateLabel(value, maxLabelLength)}
                            />
                            <RechartsTooltip content={<CustomTooltip valueFormatter={valueFormatter} />} />
                            <Bar dataKey={dataValue}>
                                {filteredAndSortedData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <NoDataMessage title={title} />
            )}
        </div>
    );
};

export default GenericBarChart;
